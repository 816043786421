import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Subject, merge, takeUntil } from 'rxjs';
import { DynamicColorResolver } from './dynamic-color.resolver';
import isNil from 'lodash-es/isNil';

@Directive({
  selector: '[aukDynamicColor]',
  standalone: true,
})
/**
 * This Directive is used to append accent color classes to components
 * Logic for generating classes: subbrands-dynamic-color-resolver.ts
 */
export class DynamicColorDirective extends NgUnsubscribe implements OnChanges {

  /**
   * This input controls if dynamic color is applied to component
   */
  @Input() public aukDynamicColor: boolean = true;

  private readonly aukDynamicColorChange$: Subject<void> = new Subject<void>();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer2: Renderer2,
    private readonly dynamicColorResolver: DynamicColorResolver,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.aukDynamicColor) {
      this.setDynamicColor();
    }
  }

  private setDynamicColor(): void {
    this.aukDynamicColorChange$.next();

    if (!this.aukDynamicColor) {
      return;
    }

    this.dynamicColorResolver.getColorClasses$()
      .pipe(
        takeUntil(
          merge(
            this.aukDynamicColorChange$,
            this.ngUnsubscribe,
          ),
        ),
      )
      .subscribe(({ addColorClassList, removeColorClassList }) => {
        if (isNil(addColorClassList) || isNil(removeColorClassList)) {
          return;
        }

        removeColorClassList.forEach((colorClass) => {
          this.renderer2.removeClass(this.elementRef.nativeElement, colorClass);
        });
        addColorClassList.forEach((colorClass) => {
          this.renderer2.addClass(this.elementRef.nativeElement, colorClass);
        });
      });
  }

}

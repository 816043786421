import { Injectable } from '@angular/core';

import { StringUtils } from '@util/util/string.utils';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { LoginVM } from '@api/aukro-api/model/login-vm';
import { PersonDto } from '@api/aukro-api/model/person-dto';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
export class FacebookNativeSDKService {

  public async loginViaFBNative(): Promise<{ loginVM: LoginVM; registrationPersonDto: PersonDto } | Nil> {
    const FACEBOOK_PERMISSIONS = [
      'email',
    ];

    const accessToken = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });

    if (accessToken.accessToken) {
      const socialUser = await FacebookLogin.getProfile<{
        name: string;
        email: string;
        first_name: string;
        last_name: string;
      }>({ fields: ['name', 'email', 'first_name', 'last_name'] });

      if (StringUtils.isBlank(socialUser.email)) {
        return null;
      }

      const loginVM: LoginVM = {
        username: socialUser.email,
        extUserId: accessToken.accessToken.userId as any, // needed retype because of facebook user id could be bigger than Number.MAX_VALUE
        registrationChannelEnum: 'FACEBOOK',
        authToken: accessToken.accessToken.token,
      };

      const registrationPersonDto: PersonDto = {
        name: socialUser.first_name,
        surname: socialUser.last_name,
      };

      return { loginVM, registrationPersonDto };
    } else {
      return null;
    }
  }

}

import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import find from 'lodash-es/find';
import isString from 'lodash-es/isString';
import { firstValueFrom, forkJoin, iif, Observable, of, zip } from 'rxjs';
import { map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { StringUtils } from '@util/util/string.utils';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { UserService } from '@shared/user/service/user.service';
import { BrowserService } from '@shared/platform/browser.service';
import { ProductProfileHelper } from '@shared/util/product/product-profile.helper';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { timeToEnd } from '@util/util/time-to-end.util';
import { GoogleAnalyticsPriceExchangeService } from './google-analytics-price-exchange.service';
import { GoogleAnalyticsConstants } from '../const/google-analytics.constats';
import cloneDeep from 'lodash-es/cloneDeep';
import { WINDOW_OBJECT } from '@util/const/window-object';
import isEmpty from 'lodash-es/isEmpty';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AukWindow } from '@shared/model/auk-window.interface';
import { VivnetworksService } from '@shared/services/vivnetworks/vivnetworks.service';
import { BrowserUtils } from '@util/util/browser.utils';
import { DELIVERY_TIME_ATTRIBUTE_ID } from '../../../app.constants';
import { OfferDetailDto } from '@api/aukro-api/model/offer-detail-dto';
import { ItemAttributeValueDto } from '@api/aukro-api/model/item-attribute-value-dto';
import { CartItemDetailDto } from '@api/aukro-api/model/cart-item-detail-dto';
import { CategoryInfoDto } from '@api/aukro-api/model/category-info-dto';
import { CartItemsBySellerDto } from '@api/aukro-api/model/cart-items-by-seller-dto';
import { CartCheckoutOptionsDto } from '@api/aukro-api/model/cart-checkout-options-dto';
import { CartCheckoutSellerFormDto } from '@api/aukro-api/model/cart-checkout-seller-form-dto';
import { ItemShippingOptionsCombinationDto } from '@api/aukro-api/model/item-shipping-options-combination-dto';
import { SelectedCartItemShippingDto } from '@api/aukro-api/model/selected-cart-item-shipping-dto';
import { CartCheckoutItemDto } from '@api/aukro-api/model/cart-checkout-item-dto';
import { CartCheckoutShippingOptionDto } from '@api/aukro-api/model/cart-checkout-shipping-option-dto';
import { EntityModelItemSearchItem } from '@api/aukro-api/model/entity-model-item-search-item';
import { ItemCategoryDto } from '@api/aukro-api/model/item-category-dto';
import { Category } from '@api/aukro-api/model/category';
import { OffersApiService } from '@api/aukro-api/api/offers-api.service';
import { OfferDetailGaDto } from '@api/aukro-api/model/offer-detail-ga-dto';
import { CartItemOverviewDto } from '@api/aukro-api/model/cart-item-overview-dto';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { GaPageType } from '@shared/google-analytics/model/ga-page.type';
import { GaCategoryDataModel } from '@shared/google-analytics/model/ga-category-data.model';
import { GaPageDataModel } from '@shared/google-analytics/model/ga-page-data.model';
import { GaPageDataParamsModel } from '@shared/google-analytics/model/ga-page-data-params.model';
import { GaTrackPageViewParamsModel } from '@shared/google-analytics/model/ga-track-page-view-params.model';
import { GaItemsProductStateEnum } from '@shared/google-analytics/model/ga-items-product-state.enum';
import { GaItemsProductStateType } from '@shared/google-analytics/model/ga-items-product-state.type';
import { GaVisitorDataModel } from '@shared/google-analytics/model/ga-visitor-data-model';
import { GaEcommerceProductDataModel } from '@shared/google-analytics/model/ga-ecommerce-product-data.model';
import { GaEcommerceTrackEventModel } from '@shared/google-analytics/model/ga-ecommerce-track-event.model';
import { GaEcommerceProductAttributeModel } from '@shared/google-analytics/model/ga-ecommerce-product-attribute.model';
import { GaEcommerceCartDataModel } from '@shared/google-analytics/model/ga-ecommerce-cart-data.model';
import { GaEcommerceDataModel } from '@shared/google-analytics/model/ga-ecommerce-data.model';
import { GaTrackCartActionParamsModel } from '@shared/google-analytics/model/ga-track-cart-action-params.model';
import { GaImpressionPairCartParamsModel } from '@shared/google-analytics/model/ga-impression-pair-cart-params.model';
import { GaTrackSellButtonClickParamsModel } from '@shared/google-analytics/model/ga-track-sell-button-click-params.model';
import { GaExposureFormSectionEnum } from '@shared/google-analytics/model/ga-exposure-form-section.enum';
import { GaRegisterFormSectionEnum } from '@shared/google-analytics/model/ga-register-form-section.enum';
import { GaCartItemMergedInfoModel } from '@shared/google-analytics/model/ga-cart-item-merged-info.model';
import { GaTrackEventType } from '@shared/google-analytics/model/ga-track-event.type';
import { GaTrackEventModel } from '@shared/google-analytics/model/ga-track-event.model';
import { GaPageTrackEventModel } from '@shared/google-analytics/model/ga-page-track-event.model';
import { GaCartTrackEventModel } from '@shared/google-analytics/model/ga-cart-track-event.model';
import { GaActionTrackEventModel } from '@shared/google-analytics/model/ga-action-track-event.model';
import { GaActionEventType } from '@shared/google-analytics/model/ga-action-event.type';
import { GaPartnersDataModel } from '@shared/google-analytics/model/ga-partners-data.model';
import { GaCartParamsModel } from '@shared/google-analytics/model/ga-cart-params.model';
import { GaCartType } from '@shared/google-analytics/model/ga-cart.type';
import { GaCartModel } from '@shared/google-analytics/model/ga-cart.model';
import { GaCartSellerPartModel } from '@shared/google-analytics/model/ga-cart-seller-part.model';
import { GaCartSellerPartItemModel } from '@shared/google-analytics/model/ga-cart-seller-part-item.model';
import { GaCartSellerPartItemCategoryModel } from '@shared/google-analytics/model/ga-cart-seller-part-item-category.model';
import { GaExposeTrackEventModel } from '@shared/google-analytics/model/ga-expose-track-event.model';
import { ExposeFormGaParamsModel } from '@shared/google-analytics/model/expose-form-ga-params.model';
import { GaAdContainerReadyTrackEventModel } from '@shared/google-analytics/model/ga-ad-container-ready-track-event.model';
import { AdContainerReadyGaParamsModel } from '@shared/google-analytics/model/ad-container-ready-ga-params.model';
import { GaBidTrackEventModel } from '@shared/google-analytics/model/ga-bid-track-event.model';
import { BidGaParamsModel } from '@shared/google-analytics/model/bid-ga-params.model';
import { GaGclidEventModel } from '@shared/google-analytics/model/ga-gclid-event.model';
import { GaCartSellerModel } from '@shared/google-analytics/model/ga-cart-seller.model';

/**
 * Responsible for sending data to the Data Layer for use in Google Tag Manager
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsTrackingService extends NgUnsubscribe {

  private trackPromise: Promise<GaTrackEventModel> = Promise.resolve<GaTrackEventModel>(null);

  /**
   * Properties which we need to reset before push to Data Layer
   */
  private needResetBeforePush: string[] = ['page', 'items', 'order', 'cart'];

  private _cartExternalIdentifier: string | Nil;

  private readonly CART_EXTERNAL_IDENTIFIER: string = 'cart.externalIdentifier';

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly tokenMonitoringService: TokenMonitoringService,
    private readonly browserService: BrowserService,
    private readonly titleService: Title,
    private readonly offerApiService: OffersApiService,
    private readonly localStorageService: LocalStorageService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly googleAnalyticsPriceExchangeService: GoogleAnalyticsPriceExchangeService,
    private readonly vivnetworksService: VivnetworksService,
  ) {
    super();
  }

  /**
   * Pushes the simple event (without any params) to Data Layer
   * @param eventType - type of event
   */
  public trackSimpleEvent(eventType: GaTrackEventType): void {
    const event: GaTrackEventModel = { event: eventType };
    return void this.createTrackPromise(event);
  }

  public trackPageView(params: GaTrackPageViewParamsModel): Promise<GaPageTrackEventModel> {
    return this.trackEvent(() => this.createTrackPageViewPromise(params)) as Promise<GaPageTrackEventModel>;
  }

  public trackAction(actionType: GaActionEventType): Promise<GaActionTrackEventModel> {
    return this.trackEvent(() => this.createActionTrackPromise(actionType));
  }

  public trackCartQuantity(params: GaTrackCartActionParamsModel): Promise<GaEcommerceTrackEventModel> {
    return this.trackEvent(() => this.createCartQuantityTrackPromise(params));
  }

  public trackCartStep(params: GaCartParamsModel): Promise<GaEcommerceTrackEventModel> {
    return this.trackEvent(() => this.createCartStepTrackPromise(params));
  }

  public trackSellButtonClick(params: GaTrackSellButtonClickParamsModel): Promise<GaActionTrackEventModel> {
    return this.trackEvent(() => this.createSellButtonActionTrackPromise(params));
  }

  public trackAdContainerReadyEvent(adContainerReadyGaParams: AdContainerReadyGaParamsModel): Promise<GaAdContainerReadyTrackEventModel> {
    return this.trackEvent(() => this.createAdContainerReadyEvent(adContainerReadyGaParams));
  }

  public trackGaExposeParams(exposeFormGaParams: ExposeFormGaParamsModel): Promise<GaExposeTrackEventModel> {
    return this.trackEvent(() => this.createExposeTrackPromise(exposeFormGaParams));
  }

  public trackExposureFormParams(sectionType: GaExposureFormSectionEnum): Promise<GaExposeTrackEventModel> {
    return this.trackEvent(() => this.createExposureFormTrackPromise(sectionType));
  }

  public trackRegisterFormParams(sectionType: GaRegisterFormSectionEnum): Promise<GaExposeTrackEventModel> {
    return this.trackEvent(() => this.createRegisterFormTrackPromise(sectionType));
  }

  public trackLoginFormParams(): Promise<GaExposeTrackEventModel> {
    return this.trackEvent(() => this.createLoginFormTrackPromise());
  }

  public trackBidEvent(bidGaParams: BidGaParamsModel): Promise<GaBidTrackEventModel> {
    return this.trackEvent(() => this.createBidEvent(bidGaParams));
  }

  public trackGclid(gclid: string): Promise<GaGclidEventModel> {
    return this.trackEvent(() => this.createGclidEvent(gclid));
  }

  public getProductVariant(offerDetailGaDto: OfferDetailGaDto): string {
    return this.getProductAttributeValue(offerDetailGaDto.attributes, 48);
  }

  public getProductAttributes({ attributes }: OfferDetailDto): GaEcommerceProductAttributeModel[] {
    const gaAttributes: GaEcommerceProductAttributeModel[] = [];
    attributes.forEach((attribute: ItemAttributeValueDto) => {
      const attributeInArray: GaEcommerceProductAttributeModel =
        find(gaAttributes, (att: GaEcommerceProductAttributeModel) => att.attributeId === att.attributeId);
      if (attributeInArray) {
        attributeInArray.values.push(attribute.attributeKey);
      } else {
        gaAttributes.push({ attributeId: attribute.attributeId, values: [attribute.attributeKey] });
      }
    });
    return gaAttributes;
  }

  public getProductCategoriesArray(offerDetail: OfferDetailDto, limit: number): string[] {
    const cats: string[] = [];
    offerDetail.category.forEach((category, index) => {
      if (index <= limit) {
        cats.push(category.name);
      }
    });
    return [...cats.reverse()];
  }

  public forceReset(properties: string[]): void {
    const object = {};
    properties.forEach((item: string) => object[item] = undefined);
    this.dataLayerPush(object);
  }

  public get isCartExternalIdentifier(): boolean {
    return !!(this._cartExternalIdentifier || this.localStorageService.getItem(this.CART_EXTERNAL_IDENTIFIER));
  }

  public resetCartExternalIdentifier(): void {
    this.localStorageService.removeItem(this.CART_EXTERNAL_IDENTIFIER);
    this._cartExternalIdentifier = null;
  }

  private getPageType(): GaPageType {
    let route: ActivatedRoute = BrowserUtils.getActiveRoute(this.activatedRoute);
    while (route) {
      const type: GaPageType = route.snapshot.data.pagetype;
      if (type) {
        return type;
      }
      route = route.parent;
    }
    return 'Undefined';
  }

  // External identifier does not change until cart is completed or cart type has changed
  private getCartExternalIdentifier(cartType: GaCartType): string {
    this._cartExternalIdentifier = this.localStorageService.getItem(this.CART_EXTERNAL_IDENTIFIER);
    const prefix: string = cartType === 'buynow' ? 'BUYNOW' : 'AUCTION';
    if (!(this._cartExternalIdentifier && this._cartExternalIdentifier.startsWith(prefix))) {
      this._cartExternalIdentifier = `${ prefix }-${ this.generateFakeDealId(10) }`;
      this.localStorageService.setItem(this.CART_EXTERNAL_IDENTIFIER, this._cartExternalIdentifier);
    }

    return this._cartExternalIdentifier;
  }

  public trackEventGeneric<T extends GaTrackEventModel = GaTrackEventModel>(
    eventData: T,
  ): Promise<T> {
    return this.createTrackPromise(
      eventData,
    );
  }

  public trackEvent(callback: () => Promise<GaTrackEventModel>): Promise<GaTrackEventModel> {
    this.trackPromise = this.trackPromise.then(callback);
    return this.trackPromise;
  }

  public createTrackPromise<T extends GaTrackEventModel = GaTrackEventModel>(trackEvent: T, promise?: Promise<T>): Promise<T> | Nil {
    if (this.platformCommonService.isServer) {
      return null;
    }

    return new Promise<T>((resolve) => {
      const event: T = {
        ...trackEvent,
        eventTimeout: 2000,
        eventCallback: () => {
          // ('== ga event ==', event);
          resolve(event);
        },
      };

      if (!isNil(promise)) {
        void promise.then((e: GaTrackEventModel) => this.pushEvent(Object.assign(event, e)));
        return;
      }

      this.pushEvent(event);
    });
  }

  private pushEvent(event: GaTrackEventModel): void {
    if (!event.event) {
      throw new Error('Property event must be set!');
    }
    const resetProperties: Record<string, undefined> = {};
    this.needResetBeforePush.forEach((key: string) => {
      if (key in event) {
        resetProperties[key] = undefined;
      }
    });
    if (Object.keys(resetProperties).length !== 0) {
      this.dataLayerPush(resetProperties);
    }
    this.dataLayerPush(event);
  }

  private dataLayerPush(data: unknown): void {
    if (this.platformCommonService.isBrowser) {
      this.window.dataLayer?.push(data);
    }
  }

  private createAdContainerReadyEvent(params: AdContainerReadyGaParamsModel): Promise<GaAdContainerReadyTrackEventModel> {
    if (!this.platformCommonService.isBrowser) {
      return;
    }
    return this.createTrackPromise<GaAdContainerReadyTrackEventModel>(null, new Promise((resolve) => {
      const event: GaAdContainerReadyTrackEventModel = {
        event: 'adContainerReady',
        ad: params,
      };
      resolve(event);
    }));
  }

  private createTrackPageViewPromise(params: GaTrackPageViewParamsModel): Promise<GaTrackEventModel> {
    return this.createTrackPromise<GaTrackEventModel<string>>(null, new Promise((resolve, reject): void => {
      params = { ...params };

      const event: GaPageTrackEventModel = {
        event: 'ga.pageview',
        page: this.preparePageData(params),
        visitor: null,
      };

      if (params.ecommerce) {
        event.ecommerce = params.ecommerce;
      }

      const pagePath = this.preparePagePath(params.pagePath);
      if (pagePath) {
        event.pagePath = pagePath;
      }

      const pageTitle = this.preparePageTitle(params.pageTitle);
      if (pageTitle) {
        event.pageTitle = pageTitle;
      }

      const partners = this.preparePartners();
      if (partners) {
        event.partners = partners;
      }

      if (params.items) {
        event.items = params.items;
      }

      this.prepareVisitorData()
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(
          {
            next: (visitor: GaVisitorDataModel) => {
              event.visitor = visitor;
              resolve(event);
            },
            error: (error) => reject(error),
          },
        );
    }));
  }

  private createActionTrackPromise(actionType: GaActionEventType): Promise<GaActionTrackEventModel> {
    const event: GaActionTrackEventModel = {
      event: 'ga.event',
    };

    let actionEventConfig: any = GoogleAnalyticsConstants.ACTION_TRACK_EVENTS;
    const path = actionType.split('.');
    for (const part of path) {
      actionEventConfig = actionEventConfig[part];

      if (!actionEventConfig) {
        throw new Error('GA track action: unknown action type for path ' + actionType + ' at part ' + part);
      }

      if ('eventCategory' in actionEventConfig) {
        event.eventCategory = actionEventConfig.eventCategory;
      }
      if ('eventAction' in actionEventConfig) {
        event.eventAction = actionEventConfig.eventAction;
      }
      if ('eventLabel' in actionEventConfig) {
        event.eventLabel = actionEventConfig.eventLabel;
      }
      if ('eventValue' in actionEventConfig) {
        event.eventValue = actionEventConfig.eventValue;
      }
    }

    return this.createTrackPromise(event);
  }

  private createCartQuantityTrackPromise(params: GaTrackCartActionParamsModel): Promise<GaEcommerceTrackEventModel> {
    if (!this.platformCommonService.isBrowser) {
      return;
    }

    return this.createTrackPromise<GaEcommerceTrackEventModel>(null, new Promise((resolve, reject) => {
      const requests: Observable<GaCartItemMergedInfoModel>[] = params.cartOverview.cartItems
        .map((item: CartItemOverviewDto) =>
          this.offerApiService.getOfferDetailGa$({ id: item?.itemId })
            .pipe(
              mergeMap((product: OfferDetailDto) => of({ cartItem: item, product })),
              mergeMap((merged: GaCartItemMergedInfoModel) => this.googleAnalyticsPriceExchangeService.recalculateCartPrices(merged))),
        );

      iif(() => requests.length > 0, forkJoin(requests), of([]) as Observable<GaCartItemMergedInfoModel[]>)
        .pipe(take(1))
        .subscribe(
          {
            next: (items: GaCartItemMergedInfoModel[]) => {
              const ecommerce: GaEcommerceDataModel = {
                currencyCode: GoogleAnalyticsConstants.GTM_CURRENCY,
                [params.type]: {
                  products: items.map((item) => this.prepareEcommerceProductData(item)),
                } as GaEcommerceCartDataModel,
              };

              const trackEvent: GaEcommerceTrackEventModel = { ecommerce };

              if (params.type === 'add') {
                trackEvent.ecommerce.add.actionField = { list: 'Category – Seznam nabídek' };
                trackEvent.ecommerce.add.products.map((item: GaEcommerceProductDataModel) => {
                  item.quantity = params.currentAmount;
                  const localStorageKey: string = GoogleAnalyticsConstants.GA_IMPRESSION_PAIR_CART_SESSION_KEY + item.id;
                  const pairParams: GaImpressionPairCartParamsModel = this.localStorageService.getItem(localStorageKey);
                  if (pairParams) {
                    item.position = pairParams.position;
                    this.localStorageService.removeItem(GoogleAnalyticsConstants.GA_IMPRESSION_PAIR_CART_SESSION_KEY + item.id.toString());
                  }
                });
              } else if (params.type === 'remove') {
                trackEvent.ecommerce.remove.products.map((item: GaEcommerceProductDataModel) => item.quantity = params.currentAmount);
              }

              trackEvent.event = params.type === 'add' ? 'ec.addToCart' : 'ec.removeFromCart';
              trackEvent.eventAction = params.type === 'add' ? 'Přidání do košíku' : 'Odebrání z košíku';

              resolve(trackEvent);
            },
            error: ((e) => reject(e)),
          },
        );
    }));
  }

  private prepareEcommerceProductData(item: GaCartItemMergedInfoModel): GaEcommerceProductDataModel {
    return {
      availability: this.getProductAvailability(item.product.endingTime),
      brand: item.product.seller.showName,
      category: this.getItemCategory(item.product),
      id: item.cartItem.itemId.toString(),
      name: item.cartItem.itemName,
      price: item.cartItem.totalPrice.amount / item.cartItem.quantity,
      quantity: item.cartItem.quantity,
      variant: this.getProductVariant(item.product),
    };
  }

  private getProductAvailability(endingTime: string): string {
    return timeToEnd(endingTime).label;
  }

  /**
   * Creates a track promise with an event.
   * @param bidGaParams
   */
  private createBidEvent(bidGaParams: BidGaParamsModel): Promise<GaBidTrackEventModel> {
    const promise: Promise<GaBidTrackEventModel> = new Promise((resolve) => {
      const event: GaBidTrackEventModel = {
        event: 'bid',
        bid: bidGaParams,
      };
      resolve(event);
    });

    return this.createTrackPromise(null, promise);
  }

  private createGclidEvent(gclid: string): Promise<{
    gclid?: string;
  } & GaTrackEventModel<GaTrackEventType | string>> {
    const event: GaGclidEventModel = {
      event: 'gclid',
      gclid,
    };

    return this.createTrackPromise(event);
  }

  private createCartStepTrackPromise(param: GaCartParamsModel): Promise<GaEcommerceTrackEventModel> {
    if (!this.platformCommonService.isBrowser) {
      return;
    }

    const eventPromise: Promise<GaEcommerceTrackEventModel> = firstValueFrom(
      of(cloneDeep(param))
        .pipe(
          mergeMap((gaCartParams: GaCartParamsModel) => this.googleAnalyticsPriceExchangeService.recalculateCartStepPrices(gaCartParams)),
          map((gaCartParams: GaCartParamsModel) => this.createCartStepTrackEvent(gaCartParams)),
        ),
    );

    return this.createTrackPromise<GaEcommerceTrackEventModel>(null, eventPromise);
  }

  private createSellerPartItemForCartStepTrackEvent(item: CartItemDetailDto): GaCartSellerPartItemModel {
    const stateOfGoods: ItemAttributeValueDto = item.itemAttributes
      .find((attribute: ItemAttributeValueDto) => attribute.attributeId === 48);
    const deliveryTime: ItemAttributeValueDto = item.itemAttributes
      .find((attribute: ItemAttributeValueDto) => attribute.attributeId === DELIVERY_TIME_ATTRIBUTE_ID);

    return {
      id: item.itemId,
      name: item.itemName,
      price: item.itemPrice?.amount,
      quantity: item.quantity,
      slug: item.seoUrl,
      type: item.itemType === 'BIDDING' ? 'bidding' : 'buynow',
      categories: item.categories.map((category: CategoryInfoDto) => ({
        id: category.id,
        name: category.name,
        slug: category.seoUrl,
      } as GaCartSellerPartItemCategoryModel)).reverse(),
      availability: deliveryTime ? deliveryTime.attributeValue : null,
      state: stateOfGoods ? GaItemsProductStateEnum[stateOfGoods.attributeKey] as GaItemsProductStateType : null,
    };
  }

  private createCartStepTrackEvent(gaCartParams: GaCartParamsModel): GaEcommerceTrackEventModel {

    // cart property
    const cart: GaCartModel = {
      type: gaCartParams.type,
      step: gaCartParams.step,
      currency: GoogleAnalyticsConstants.GTM_CURRENCY,
      externalIdentifier: this.getCartExternalIdentifier(gaCartParams.type),
      totalPrice: null,
      totalPriceWithoutShipping: null,
      totalShippingPrice: null,
      sellers: null,
    };

    if (gaCartParams.thankYou && gaCartParams.type === 'buynow') {
      cart.previousPurchaseDate = gaCartParams.thankYou.previousPurchaseDate;
      cart.existingEmail = gaCartParams.thankYou.existingEmail;
      cart.totalRevenueWithoutShipping = gaCartParams?.thankYou?.revenue?.amount;
    }

    const sellers: GaCartSellerModel[] = [];
    let cartTotalPrice: number = null;
    let cartTotalPriceWithoutShipping: number = null;
    let cartTotalShippingPrice: number = null;

    // preview page
    if (gaCartParams.step === 0) {

      // all information are in cartItemsBySeller
      gaCartParams.cartItemsBySeller.forEach((seller: CartItemsBySellerDto) => {
        const items: GaCartSellerPartItemModel[] = [];
        let totalPrice: number = 0;

        seller.cartItemsDetailDto.forEach((item: CartItemDetailDto) => {
          items.push(this.createSellerPartItemForCartStepTrackEvent(item));
          totalPrice += item.totalPrice.amount;
        });

        sellers.push({
          sellerId: seller.sellerId,
          sellerName: seller.sellerLogin,
          totalPrice,
          totalPriceWithoutShipping: totalPrice,
          totalShippingPrice: null,
          parts: [{
            isPaid: null,
            items,
            shipping: {
              name: null,
              code: null,
              price: null,
            },
            payment: {
              name: null,
              code: null,
            },
          }],
        });

        cartTotalPrice += totalPrice;
        cartTotalPriceWithoutShipping += totalPrice;
      });

    } else {

      // iterate by ordered sellers
      gaCartParams.sellerOptions.forEach((seller: CartCheckoutOptionsDto) => {
        const parts: GaCartSellerPartModel[] = [];
        let totalPriceWithoutShipping: number = null;
        let totalShippingPrice: number = null;

        const sellerForm: CartCheckoutSellerFormDto = gaCartParams.sellerPayments
          .find((item: CartCheckoutSellerFormDto) => item.sellerId === seller.sellerId);

        // get seller from cartItemsBySeller, it has more information about items
        const sellerData: CartItemsBySellerDto = gaCartParams.cartItemsBySeller
          .find((item: CartItemsBySellerDto) => item.sellerId === seller.sellerId);

        // iterate seller's groups
        seller.groupedByShippingOptions.forEach((group: ItemShippingOptionsCombinationDto, groupIndex: number) => {
          const selectionGroup: SelectedCartItemShippingDto = sellerForm.selectedCartItemShippingMethods[groupIndex];
          const part: GaCartSellerPartModel = {
            isPaid: null,
            items: [],
            shipping: {
              name: null,
              code: null,
              price: null,
            },
            payment: {
              name: null,
              code: null,
            },
          };

          // iterate items in group
          group.items.forEach((groupItem: CartCheckoutItemDto) => {
            // get detailed item information
            const itemData: CartItemDetailDto = sellerData.cartItemsDetailDto
              .find((item: CartItemDetailDto) => item.itemId === groupItem.itemId);
            part.items.push(this.createSellerPartItemForCartStepTrackEvent(itemData));
            totalPriceWithoutShipping += itemData.quantity * itemData.itemPrice.amount;
          });

          if (gaCartParams.step >= 2) {
            // get group shipping option
            const shippingOption: CartCheckoutShippingOptionDto = group.shippingOptions
              .find((item: CartCheckoutShippingOptionDto) => item.shippingMethodId === selectionGroup.shippingOptionId);
            part.shipping = {
              name: shippingOption.name,
              code: shippingOption.code,
              price: shippingOption.price?.amount,
            };
            totalShippingPrice += shippingOption.price?.amount;

            if (gaCartParams.step === 3 && gaCartParams.type === 'buynow') {
              part.buynowId = gaCartParams.thankYou?.sellers
                ?.find(cartThankYouSeller => seller.sellerId === cartThankYouSeller.seller?.userId)
                ?.dealTransactionId;
            }
          }

          parts.push(part);
        });

        sellers.push({
          sellerId: seller.sellerId,
          sellerName: seller.sellerLogin,
          totalPrice: totalPriceWithoutShipping + totalShippingPrice,
          totalPriceWithoutShipping,
          totalShippingPrice,
          parts,
        });

        cartTotalPrice += (totalPriceWithoutShipping + totalShippingPrice);
        cartTotalPriceWithoutShipping += totalPriceWithoutShipping;
        if (cart.step >= 2) {
          cartTotalShippingPrice += totalShippingPrice;
        }
      });
    }

    if (sellers.length > 0) {
      cart.sellers = sellers;
    }

    if (typeof cartTotalPrice === 'number') {
      cart.totalPrice = cartTotalPrice;
    }
    if (typeof cartTotalPriceWithoutShipping === 'number') {
      cart.totalPriceWithoutShipping = cartTotalPriceWithoutShipping;
    }
    if (typeof cartTotalShippingPrice === 'number') {
      cart.totalShippingPrice = cartTotalShippingPrice;
    }

    if (gaCartParams.step === 3) {
      if (gaCartParams.type === 'buynow') {
        cart.userId = gaCartParams.thankYou?.buyerId;
      }

      // cart steps are completed, external identifier must be removed
      this.resetCartExternalIdentifier();
    }

    // cart step track event
    const trackEvent: GaCartTrackEventModel = {
      event: gaCartParams.action,
      cart,
    };

    return trackEvent;
  }

  private createSellButtonActionTrackPromise(params: GaTrackSellButtonClickParamsModel): Promise<GaActionTrackEventModel> {
    const event: GaActionTrackEventModel = {
      event: 'ga.event',
    };

    event.eventCategory = 'Click';
    event.eventAction = 'Vystavit předmět';
    event.eventLabel = params.location;

    return this.createTrackPromise(event);
  }

  private createExposeTrackPromise(params: ExposeFormGaParamsModel): Promise<GaExposeTrackEventModel> {
    if (!this.platformCommonService.isBrowser) {
      return;
    }
    return this.createTrackPromise<GaEcommerceTrackEventModel>(null, new Promise((resolve) => {
      const event: GaExposeTrackEventModel = {
        event: 'exposeItem',
        exposeForm: params,
      };
      resolve(event);
    }));
  }

  private createExposureFormTrackPromise(section: GaExposureFormSectionEnum): Promise<GaActionTrackEventModel> {
    const event: GaActionTrackEventModel = {
      event: 'trackExposureFormClicks',
      eventCategory: 'Ux',
      eventAction: section,
      eventLabel: 'Exposure form',
      eventValue: '0',
    };

    return this.createTrackPromise(event);
  }

  private createRegisterFormTrackPromise(section: GaRegisterFormSectionEnum): Promise<GaActionTrackEventModel> {
    const event: GaActionTrackEventModel = {
      event: 'registerConsumer',
      eventCategory: 'Core',
      eventAction: section,
      eventLabel: 'Consumer',
      eventValue: '0',
    };

    return this.createTrackPromise(event);
  }

  private createLoginFormTrackPromise(): Promise<GaActionTrackEventModel> {
    const event: GaActionTrackEventModel = {
      event: 'signUp',
      eventCategory: 'Core',
      eventAction: 'Sign up',
      eventLabel: 'Regular sign up',
      eventValue: '0',
    };

    return this.createTrackPromise(event);
  }

  private getItemCategory(itemDetailDto?: OfferDetailGaDto, itemSearchItem?: EntityModelItemSearchItem): string[] {
    let categories = [];
    let currentCategory: string;

    if (itemDetailDto) {
      const categoriesModel = ProductProfileHelper.parseCategoriesModel(itemDetailDto.category);
      categories = itemDetailDto.category;
      currentCategory = categoriesModel?.leafCategory?.name;
    }

    if (itemSearchItem) {
      categories = itemSearchItem.categoryPath;
    }

    return categories
      .map((c: ItemCategoryDto | Category) => c.name)
      .concat(currentCategory ? currentCategory : '')
      .filter((c: string) => {
        if (c !== '' || c !== null) {
          return c;
        }
      });
  }

  private getProductAttributeValue(parameters: ItemAttributeValueDto[], id: number): string {
    const param = parameters && parameters.find((p: ItemAttributeValueDto) => p.attributeId === id);
    return param && param.attributeValue || undefined;
  }

  private preparePageData(params: GaPageDataParamsModel): GaPageDataModel {
    const path = params.path == null ? this.router.url : params.path;
    const type = this.getPageType();
    const title = this.prepareTitle(params.title);

    const res: GaPageDataModel = {
      path,
      type,
      ...this.prepareCategoryData(params.categories, type),
    };

    if (title) {
      res.title = title;
    }

    return res;
  }

  private prepareCategoryData(categories: ItemCategoryDto[], pageType: GaPageType): GaCategoryDataModel {
    if (!categories || !categories.length) {
      return null;
    }

    const category: string[] = [];
    const categoryId: number[] = [];

    categories
      .filter((c: ItemCategoryDto) => c && c.id != null && c.name != null)
      .sort((category1: ItemCategoryDto, category2: ItemCategoryDto) => category1.level - category2.level)
      .forEach((c: ItemCategoryDto) => {
        category.push(c.name);
        categoryId.push(c.id);
      });

    // The categories contain also category of the LP itself (e.g. because of breadcrumb).
    // However, for the purpose of sending data to GA we want to send only categories without the category of LP
    // which is always the last one in the list of categories. That's why we remove the last item from the list in case of LP.
    if (!isEmpty(categories) && pageType === 'Landing page') {
      category.splice(categories.length - 1);
      categoryId.splice(categories.length - 1);
    }

    return {
      category: category.length === 1 ? category[0] : category,
      categoryId: categoryId.length === 1 ? categoryId[0] : categoryId,
    };
  }

  private prepareVisitorData(): Observable<GaVisitorDataModel> {
    return zip(
      this.userService.getCurrentUserProfileMinimal(),
      this.userService.getActualStatistics(),
      this.userService.getActualUserProfileStatistics(),
      this.tokenMonitoringService.findToken(),
    ).pipe(
      map(([user, actualStatistics, userProfileStatistics, token]) => {
        const visitorData: GaVisitorDataModel = {
          loginState: user ? 'Logged in' : 'Anonymous',
        };
        if (visitorData.loginState === 'Logged in') {
          visitorData.type = user.companyAccount ? 'company' : 'private';
          visitorData.allowedSell = actualStatistics.allowedSale;
          visitorData.hashedEmail = actualStatistics.hashedEmail;
          visitorData.hashedPhoneNumber = actualStatistics.hashedPhoneNumber;
          visitorData.userId = user.userId;
          visitorData.itemsExposedCount = StringUtils
            .isBlank(userProfileStatistics.exposureCount) ? 'No info' : userProfileStatistics.exposureCount;
          visitorData.buyerDealCount = StringUtils
            .isBlank(userProfileStatistics.buyerDealCount) ? 'No info' : userProfileStatistics.buyerDealCount;
          visitorData.simpleExposeEnabled = actualStatistics.simpleExposeEnabled;
        }
        visitorData.registeredUserCount = token?.registeredUserCount ? token.registeredUserCount : 0;
        visitorData.firstRegisteredUserTime = token?.firstRegisteredUserTime ? token.firstRegisteredUserTime : 'not set';
        return visitorData;
      }));
  }

  private preparePagePath(pagePath: string | number): string {
    if (isString(pagePath)) {
      return pagePath;
    }

    const pageNumber = +pagePath;
    if (pageNumber < 2 || pagePath == null) {
      return null;
    }

    const url = this.browserService.getPathname(this.router.url);
    return `${ url }${ url.endsWith('/') ? '' : '/' }${ pageNumber }?virtual=true`;
  }

  private prepareTitle(title?: string | boolean): string {
    if (title === true || title == null) {
      return this.titleService.getTitle();
    }
    return title as string;
  }

  private preparePageTitle(pageTitle?: string | boolean): string {
    if (pageTitle === true) {
      return this.titleService.getTitle();
    }
    return pageTitle as string;
  }

  private generateFakeDealId(length: number = 5): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  private preparePartners(): GaPartnersDataModel {

    const partners: GaPartnersDataModel = {};

    /* START vivnetworks */

    const vivnetworksCje = this.vivnetworksService.getValidVivnetworksCje();
    if (!isNil(vivnetworksCje)) {
      partners.vivnetworks = {
        cjevent: vivnetworksCje,
      };
    }

    /* END vivnetworks */

    return Object.keys(partners).length > 0 ? partners : null;
  }

  public setGaImpressionPairCartSession(itemId: string, pairParams: GaImpressionPairCartParamsModel): void {
    this.localStorageService.setItem(GoogleAnalyticsConstants.GA_IMPRESSION_PAIR_CART_SESSION_KEY + itemId, pairParams);
  }

}

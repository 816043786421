import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { AukWindow } from '@shared/model/auk-window.interface';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { Observable, catchError, from, map, mergeMap, of, throwError } from 'rxjs';

const DIRTY_LIST_OF_CONTENT_EXEPTIONS_ENDPOINTS = ['/offers-v2/import', '/unsubscribe/{', '/images', 'message/attachment',
  'conversation/contactForm'];
const DIRTY_LIST_OF_NATIVE_EXEPTIONS_ENDPOINTS = [RestHttpClientService.WS_URL, 'assets', '/translation'];

/**
 * This interceptor will check for exceptions for native API calls and use CapacitorWebFetch (Web Fetch - non Native)
 * to fix some features that are not availible in HTTPNative Plugin (relative paths, web sockets, etc.)
 * Also it fixes problem with correct content type for HTTPNative Plugin.
 */
@Injectable()
export class NativeHttpInterceptor implements HttpInterceptor {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
  ) {
  }

  public intercept<T extends BodyInit>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.useWebFetchForNative(request)) {
      return this.handleNativeWebRequest(request);
    }

    if (this.replaceContentType(request)) {
      return of(request)
        .pipe(
          mergeMap(() => next.handle(request.clone({ setHeaders: this.getAuthHeaders() })),
          ),
        );
    }

    return next.handle(request);
  }

  private getAuthHeaders(): Record<string, string> {
    const headers: { [key: string]: string } = {};
    headers['Content-Type'] = 'application/json';

    return headers;
  }

  private replaceContentType(request: HttpRequest<unknown>): boolean {
    const isContentExeption = DIRTY_LIST_OF_CONTENT_EXEPTIONS_ENDPOINTS.some(singleUrl => request.url.includes(singleUrl));
    if (PlatformCommonService.isNativeApp && request.method === 'POST' && !isContentExeption) {
      return true;
    }
  }

  private useWebFetchForNative(request: HttpRequest<unknown>): boolean {
    const isNativeFetchException = DIRTY_LIST_OF_NATIVE_EXEPTIONS_ENDPOINTS.some(singleUrl => request.url.includes(singleUrl));
    if (PlatformCommonService.isNativeApp && isNativeFetchException) {
      return true;
    }
  }

  private handleNativeWebRequest<T extends BodyInit>(request: HttpRequest<T>): Observable<HttpEvent<unknown>> {
    const headersObject = request.headers.keys().reduce((headers, key) => {
      headers[key] = request.headers.get(key);
      return headers;
    }, {});

    return from(this.window.CapacitorWebFetch(request.url, {
      method: request.method,
      headers: headersObject,
      body: request.body,
    }))
      .pipe(
        mergeMap((response: Response) => {
          const contentType: string = response.headers.get('content-type');
          const promise: Promise<unknown> = contentType?.includes('application/json')
            ? response.json()
            : response.text();

          return from(promise)
            .pipe(
              map((data) => new HttpResponse({
                body: data,
                status: response.status,
                statusText: response.statusText,
              })),
              catchError(error => throwError(() => new HttpErrorResponse({
                error,
                status: error.status || 500,
                statusText: error.statusText || 'Internal Server Error',
              }))),
            );
        }),
        catchError(error => throwError(() => new HttpErrorResponse({
          error,
          status: error.status || 500,
          statusText: error.statusText || 'Internal Server Error',
        }))),
      );
  }

}

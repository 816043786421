import { Injectable } from '@angular/core';
import { map, take, takeUntil } from 'rxjs/operators';
import { ShowListingEventResponseDto } from '@api/generated/defs/ShowListingEventResponseDto';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { Observable } from 'rxjs';
import { FallbackReason } from '@shared/services/personalization/model/fallback-reason.type';
import { MEASUREMENT_AUKRO_SPLIT_GROUP_VALUES } from '@shared/services/personalization/personalization-measurement.helper';
import { MeasurementObjectCode, YuspSplitGroupType } from '@shared/services/personalization/yusp-personalization.helper';
import { environmentConstants } from '@env-constants';
import { ShowEventPropertiesDto } from '@api/generated/defs/ShowEventPropertiesDto';
import { CookieService } from '@common/cookie/service/cookie.service';
import { ClickEventPropertiesDto } from '@api/generated/defs/ClickEventPropertiesDto';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { Nil } from '@util/helper-types/nil';
import { PersonalizationConstants } from '@shared/services/personalization/const/personalization.constants';

@Injectable({
  providedIn: 'root',
})
export class RecommendationPersonalizationMeasurementService extends NgUnsubscribe {

  private readonly CLICK_ON_RECOMMENDATION_TITLE_RANK: number = -1;
  private readonly CLICK_ON_RECOMMENDATION_SHOW_MORE_CARD_RANK: number = -2;

  constructor(
    private readonly cookieService: CookieService,
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
  ) {
    super();
  }

  public clickOnRecommendationItem(
    itemId: number,
    itemPosition: number,
    showId: number,
    measurementObjectCode: MeasurementObjectCode | string,
    fallbackReason: FallbackReason | Nil = null,
    predefinedSplitGroup: YuspSplitGroupType | Nil = null,
    measurementCookie: string = environmentConstants.RECO1_COOKIE_SPLIT_GROUP_NAME,
  ): void {
    const calculatedSplitGroupValue: string | Nil = this.getUsedSplitGroupValue(measurementCookie, fallbackReason, predefinedSplitGroup);

    this.personalizationMeasurementService.clickMeasurement$({
      itemId,
      elementRank: itemPosition,
      showId,
      measurementObjectCode,
      splitGroups: { [measurementCookie]: calculatedSplitGroupValue },
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public clickOnRecommendationTitle(
    showId: number,
    measurementObjectCode: MeasurementObjectCode | string,
    fallbackReason: FallbackReason = null,
  ): void {

    const measurementCookie = environmentConstants.RECO1_COOKIE_SPLIT_GROUP_NAME;
    const calculatedSplitGroupValue: string | Nil = this.getUsedSplitGroupValue(measurementCookie, fallbackReason);

    this.personalizationMeasurementService.clickMeasurement$({
      elementRank: this.CLICK_ON_RECOMMENDATION_TITLE_RANK,
      showId,
      measurementObjectCode,
      splitGroups: { [measurementCookie]: calculatedSplitGroupValue },
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public clickOnRecommendationShowMoreCard(
    showId: number,
    measurementObjectCode: MeasurementObjectCode | string,
    fallbackReason: FallbackReason = null,
  ): void {
    const measurementCookie = environmentConstants.RECO1_COOKIE_SPLIT_GROUP_NAME;
    const calculatedSplitGroupValue: string | Nil = this.getUsedSplitGroupValue(measurementCookie, fallbackReason);

    this.personalizationMeasurementService.clickMeasurement$({
      elementRank: this.CLICK_ON_RECOMMENDATION_SHOW_MORE_CARD_RANK,
      showId,
      measurementObjectCode,
      splitGroups: { [measurementCookie]: calculatedSplitGroupValue },
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public clickOnFollowRecommendationItem(
    showId: number,
    measurementObjectCode: MeasurementObjectCode | string,
    itemId: number,
    isFollowed: boolean,
  ): void {

    const properties: ClickEventPropertiesDto = (isFollowed) ? { removeWatchOnly: true } : { addToWatchOnly: true };
    this.personalizationMeasurementService.clickMeasurement$({
      showId,
      itemId,
      measurementObjectCode,
      properties,
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public showRecommendationItem$(
    measurementObjectCode: MeasurementObjectCode | string,
    itemIds?: number[],
    fallbackReason: FallbackReason | Nil = null,
    predefinedSplitGroup: YuspSplitGroupType | Nil = null,
    showEventProperties: ShowEventPropertiesDto | Nil = null,
    measurementCookie: string = environmentConstants.RECO1_COOKIE_SPLIT_GROUP_NAME,
  ): Observable<number> {
    const calculatedSplitGroupValue: string | Nil = this.getUsedSplitGroupValue(measurementCookie, fallbackReason, predefinedSplitGroup);

    return this.personalizationMeasurementService.showMeasurement$({
      items: itemIds || [],
      measurementObjectCode,
      splitGroups: { [measurementCookie]: calculatedSplitGroupValue },
      properties: showEventProperties,
    })
      .pipe(
        map((response: ShowListingEventResponseDto) => response?.showId),
      );
  }

  /**
   * Gets the split group value which has been used for recommendation call. Logic is based on the following order of priority:
   *  1. `fallbackReason` if provided
   *  2. `predefinedSplitGroup` if provided
   *  3. split group from cookie
   * @param measurementCookie the name of the cookie to retrieve the split group value
   * @param fallbackReason fallback reason (used when the original user's split group has not been used for some reason)
   * @param predefinedSplitGroup predefined split group (used when no A/B test is active and a single group is being applied)
   * @returns split group value, or Nil if none found
   */
  private getUsedSplitGroupValue(
    measurementCookie: string,
    fallbackReason: FallbackReason | Nil,
    predefinedSplitGroup: YuspSplitGroupType | Nil = null,
  ): string | Nil {
    if (isNotNil(fallbackReason)) {
      // the fallback algorithm is always marked as 'aukro' appended with the reason why the original call was wrong or couldn't happen
      return MEASUREMENT_AUKRO_SPLIT_GROUP_VALUES[fallbackReason] ?? PersonalizationConstants.MEASUREMENT_SPLIT_GROUP_VALUE_AUKRO;
    }

    if (isNotNil(predefinedSplitGroup)) {
      return predefinedSplitGroup;
    }

    return this.cookieService.get(measurementCookie);
  }

}

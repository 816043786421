import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { ButtonSizeType } from '@common/ui-kit/component/button/model/button-size.type';
import { ButtonRadiusType } from '@common/ui-kit/component/button/model/button-radius.type';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { loadingIndicator } from '@util/rxjs-operators/loading-indicator';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '@common/ui-kit/component/spinner/component/spinner.component';
import { SpinnerSize } from '@common/ui-kit/component/spinner/model/spinner-size';
import { Nil } from '@util/helper-types/nil';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { ButtonWeightType } from '../model/button-weight.type';

@Component({
  selector: 'auk-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
  ],
})
export class ButtonComponent extends ColorCombinationInputComponent implements OnChanges, OnInit {

  @Input() public isDisabled: boolean = false;

  @Input() public isLoading: boolean = false;

  @Input() public type: 'button' | 'reset' | 'submit' = 'button';

  @Input() public size: ButtonSizeType = 'MD';

  @Input() public weight: ButtonWeightType = 'BOLD';

  @Input() public chin: boolean = null;

  @Input() public radius: ButtonRadiusType = 'DEFAULT';

  /**
   * String which is set on inner <button> element as `name` attribute
   */
  @Input() public nameAttr: string | Nil;

  @Input() public transitionDurationMs: 100 | 200 = 200;

  @Output() public buttonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild('buttonElm')
  private readonly buttonElement: ElementRef<HTMLButtonElement>;

  protected buttonClasses: string[] = [];
  protected isLoadingShown: boolean = false;
  protected spinnerSize: SpinnerSize | Nil;

  private isLoadingObs$: Subject<boolean> = new Subject<boolean>();

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super(elementRef);
  }

  public ngOnInit(): void {
    this.buttonClasses = this.getButtonClass(this.size, this.radius, this.chin, this.weight);

    this.isLoadingObs$
      .pipe(
        loadingIndicator(this.ngZoneUtilService),
        // When loading is initial state, show it immediately
        startWith(this.isLoading),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isLoadingShown) => {
        this.isLoadingShown = isLoadingShown;
        this.changeDetectorRef.markForCheck();
      });
  }

  public override ngOnChanges(changes: AukSimpleChanges<ButtonComponent>): void {
    super.ngOnChanges(changes);

    if (changes.size || changes.radius) {
      this.buttonClasses = this.getButtonClass(this.size, this.radius, this.chin, this.weight);
      this.spinnerSize = this.getSpinnerSize(this.size);
    }

    if (changes.isLoading) {
      this.isLoadingObs$.next(this.isLoading);
    }
  }

  public focus(): void {
    this.buttonElement?.nativeElement?.focus();
  }

  private getButtonClass(
    size: ButtonSizeType,
    radius: ButtonRadiusType,
    chin: boolean,
    weight: ButtonWeightType,
  ): string[] {
    return [
      ButtonComponent.getButtonSizeClass(size ?? 'MD'),
      ButtonComponent.getButtonRadiusClass(radius ?? 'DEFAULT'),
      ButtonComponent.getButtonWeightClass(weight ?? 'BOLD'),
      ...ButtonComponent.getButtonChinClasses(chin ?? false),
    ];
  }

  protected onButtonClick(event: MouseEvent): void {
    if (this.isDisabled || this.isLoading) {
      // If the button is disabled or loading we do not want to trigger any action on click.
      // However, in some cases, it may trigger an action in the parent component, such as navigation.
      // To avoid this, we need to prevent the click from being propagated
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    this.buttonClick.emit(event);
  }

  private getSpinnerSize(size: ButtonSizeType): SpinnerSize {
    if (size === 'SM') {
      return 'XS';
    }
    if (size === 'SM-NORMAL') {
      return 'SM';
    }
    if (size === 'MD-NORMAL') {
      return 'MD';
    }
    return size;
  }

  private static getButtonSizeClass(size: ButtonSizeType): string {
    return `size-${ size.toLowerCase() }`;
  }

  private static getButtonChinClasses(chin: boolean): string[] {
    if (chin) {
      return ['!tw-border-b-surface-accent-button-hover', '!tw-border-b-2'];
    }
    return [''];
  }

  private static getButtonRadiusClass(radiusType: ButtonRadiusType): string {
    switch (radiusType) {
      case 'ROUNDED':
        return 'tw-rounded-full';
      case 'SM':
        return 'tw-rounded';
      case 'DEFAULT':
      default:
        return 'tw-rounded-lg';
    }
  }

  private static getButtonWeightClass(weightType: ButtonWeightType): string {
    switch (weightType) {
      case 'NORMAL':
        return 'tw-font-normal';
      case 'MEDIUM':
        return 'tw-font-medium';
      case 'LIGHT':
        return 'tw-font-light';
      case 'BOLD':
      default:
        return 'tw-font-bold';
    }
  }

}

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { captureException, Scope, withScope } from '@sentry/browser';
import isNil from 'lodash-es/isNil';
import { BehaviorSubject, Observable, of, Subject, throwError, zip } from 'rxjs';
import { catchError, debounceTime, filter, finalize, groupBy, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { BoughtItemExtendedDto } from '../../../../typings/original/internal';
import { ZasilkovnaShippingCodeEnum, ZasilkovnaWidgetHelper } from '@shared/pickup-point-widget/zasilkovna-widget/zasilkovna-widget.helper';
import { BalikovnaShippingCodeEnum, BalikovnaWidgetHelper } from '@shared/pickup-point-widget/balikovna-widget/balikovna-widget.helper';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { PaymentService } from '@shared/services/payment/payment.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { RegistryService } from '@shared/registry/registry.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { CartState } from '../model/cart-state';
import { CartStep } from '../model/cart-step.enum';
import { CartType } from '../model/cart-type.enum';
import { cartErrorMessages } from '../helper/cart.helper';
import { ArrayUtils } from '@util/util/array.utils';
import { CrossTabMessagingService } from '@shared/services/cross-tab-messaging/service/cross-tab-messaging.service';
import { ToastService } from '@common/toast/service/toast.service';
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import { UserCurrencyPreferenceService } from '@shared/currency/service/user-currency-preference.service';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { HttpError } from '@shared/rest/model/http-error';
import { MoneyCalcUtils } from '@shared/currency/util/money-calc.utils';
import { CookieService } from '@common/cookie/service/cookie.service';
import { DomainService } from '@shared/platform/domain.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { PluginListenerHandle } from '@capacitor/core';
import { DomainUtils } from '@shared/platform/domain.utils';
import { CartCheckoutAddressFormDto } from '@api/aukro-api/model/cart-checkout-address-form-dto';
import { DefaultMessageSourceResolvable } from '@api/aukro-api/model/default-message-source-resolvable';
import { CartOverviewDto } from '@api/aukro-api/model/cart-overview-dto';
import { CartItemFormDto } from '@api/aukro-api/model/cart-item-form-dto';
import { RegistryApiService } from '@api/aukro-api/api/registry-api.service';
import { CartApiService } from '@api/aukro-api/api/cart-api.service';
import { CartAuctionApiService } from '@api/aukro-api/api/cart-auction-api.service';
import { CardPaymentApiService } from '@api/aukro-api/api/card-payment-api.service';
import { CartDetailDto } from '@api/aukro-api/model/cart-detail-dto';
import { CartItemOverviewDto } from '@api/aukro-api/model/cart-item-overview-dto';
import { CartCheckoutFormDto } from '@api/aukro-api/model/cart-checkout-form-dto';
import { CartThankYouDto } from '@api/aukro-api/model/cart-thank-you-dto';
import { CartCheckoutSellerFormDto } from '@api/aukro-api/model/cart-checkout-seller-form-dto';
import { SelectedCartItemShippingDto } from '@api/aukro-api/model/selected-cart-item-shipping-dto';
import { CardPaymentCreatedDto } from '@api/aukro-api/model/card-payment-created-dto';
import { CartItemsBySellerDto } from '@api/aukro-api/model/cart-items-by-seller-dto';
import { CartItemDetailDto } from '@api/aukro-api/model/cart-item-detail-dto';
import { CartThankYouSellerDto } from '@api/aukro-api/model/cart-thank-you-seller-dto';
import { BoughtItemDto } from '@api/aukro-api/model/bought-item-dto';
import { CartCheckoutDto } from '@api/aukro-api/model/cart-checkout-dto';
import { FieldError } from '@api/aukro-api/model/field-error';
import { UserChipSmallDto } from '@api/aukro-api/model/user-chip-small-dto';
import { CartCheckoutOptionsDto } from '@api/aukro-api/model/cart-checkout-options-dto';
import { ItemShippingOptionsCombinationDto } from '@api/aukro-api/model/item-shipping-options-combination-dto';
import { CartCheckoutShippingOptionDto } from '@api/aukro-api/model/cart-checkout-shipping-option-dto';
import { RegistryItemDto } from '@api/aukro-api/model/registry-item-dto';
import { RegistryCountryItemDto } from '@api/aukro-api/model/registry-country-item-dto';
import { PayByLinkDto } from '@api/aukro-api/model/pay-by-link-dto';
import { CartCheckoutItemDto } from '@api/aukro-api/model/cart-checkout-item-dto';
import { AddressDto } from '@api/aukro-api/model/address-dto';
import { EmailDto } from '@api/aukro-api/model/email-dto';
import { AuctionCartContentDto } from '@api/aukro-api/model/auction-cart-content-dto';
import { CartItemChangeType } from '@shared/cart/model/cart-item-change.type';
import { CartItemDetailDtoChanged } from '@shared/cart/model/cart-item-detail-dto-changed';
import { SellerCartItemsChangedResult } from '@shared/cart/model/seller-cart-items-changed-result';
import { SellerCartItemsChanged } from '@shared/cart/model/seller-cart-items-changed';
import { CartConstants } from '@shared/cart/constant/cart.constants';
import { CookieUtil } from '@common/cookie/util/cookie.util';
import { MoneyDto } from '@api/aukro-api/model/money-dto';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { GaTrackCartActionParamsModel } from '@shared/google-analytics/model/ga-track-cart-action-params.model';
import { GaClientInformationService } from '@shared/google-analytics/service/ga-client-information.service';

export const PAY_VIA_AUKRO_METHOD_ID = 6;
export const PAY_VIA_AUKRO_METHOD_CODE = 'PAY_VIA_AUKRO';

// local storage keys
const PAYU_BANK = 'cart.bank';

interface CartMergedAddresses {
  deliveryAddressId: number;
  deliveryAddressManual: CartCheckoutAddressFormDto;
}

interface CartItemValidation {
  [key: string]: CartItemValidationError;
}

interface CartItemValidationError {
  [key: string]: DefaultMessageSourceResolvable;
}

@Injectable({
  providedIn: 'root',
})
export class CartService extends BaseDestroy implements OnDestroy {

  /**
   * Name of query param which is present in URL to display error message about trying to buy only user's own items
   */
  public static readonly QUERY_PARAM_NAME_OWN_ITEMS: string = 'ownItems';

  public cartState: CartState = {};
  public cartItemsChanged = new Subject<SellerCartItemsChangedResult>();
  public collectedEmail: string; // Email address to purchase without registration.
  /** Stored deal IDs from the first step of auction cart. Use for remembering selected items when the user goes back from step 2. */
  public storedDealIds: number[] = [];
  public readonly errorMessages = cartErrorMessages;
  public readonly validationKeysDeletedFromCart: CartItemChangeType[] = [
    'OFFER_EXPIRED',
    'USER_IS_ON_BLACKLIST',
    'USER_NOT_VERIFIED',
    'UNREGISTERED_USER_CANT_PAY_VIA_AUKRO',
    'OFFER_IS_HIDDEN',
  ];
  private cartOverview = new BehaviorSubject<CartOverviewDto>(null);
  private step = new BehaviorSubject<CartStep>(null);
  private itemToUpdate = new Subject<CartItemFormDto>();
  private quantityUpdate = new Subject<GaTrackCartActionParamsModel>();
  // TODO(PDEV-10719) Evaluate and remove empty pickup point logging
  private emptyPickupPointLogs: string[] = [];
  private inAppBrowserUrlChangeEvent: PluginListenerHandle;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly authService: AuthenticationService,
    private readonly registryService: RegistryService,
    private readonly registryApiService: RegistryApiService,
    private readonly paymentService: PaymentService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly platformService: PlatformService,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly gaClientInformationService: GaClientInformationService,
    private readonly cartAuctionService: CartAuctionApiService,
    private readonly cardPaymentService: CardPaymentApiService,
    private readonly translateService: TranslateService,
    private readonly cartApiService: CartApiService,
    private readonly crossTabMessagingService: CrossTabMessagingService,
    private readonly toastService: ToastService,
    private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService,
    private readonly cookieService: CookieService,
    private readonly domainService: DomainService,
  ) {
    super();
    this.quantityUpdate
      .pipe(
        groupBy((params: GaTrackCartActionParamsModel) => params.cartOverview.cartItems[0].cartItemId),
        mergeMap((param) => param.pipe(debounceTime(1500))),
      )
      .subscribe((params: GaTrackCartActionParamsModel) =>
        void this.googleAnalyticsTrackingService.trackCartQuantity(params));

    // when not-logged user logs in and returns back to the second cart step, reload user addresses
    this.loginStatusChangedInSecondStepAfterLogin()
      .pipe(
        filter((inSecondStep) => inSecondStep),
        mergeMap(() => this.cartApiService.showCartDetail$()),
        tap(() => this.reloadCart()),
      ) // call cart overview to get a new cart cookie
      .subscribe((data: CartOverviewDto) => {
        if (data.cart) {
          this.reloadUserAddresses();
        } else {
          // when user tries to buy only its own items
          void this.router.navigate(['/kosik'], {
            queryParams: {
              [CartService.QUERY_PARAM_NAME_OWN_ITEMS]: true,
            },
          });
        }
      });

    // whenever login status is changed (except login in the second step), reload the cart overview
    this.loginStatusChangedInSecondStepAfterLogin()
      .pipe(filter((inSecondStep) => !inSecondStep))
      .subscribe(() => this.loadCartOverview());

    // when user logs out, forget state of the second and third step
    this.authService.getLoginStatusChange()
      .pipe(
        filter((loggedIn: boolean) => !loggedIn),
        // Reset user-related cart data on log out
        tap(() => this.resetUserCartData()),
        filter(() => this.isCart()),
      )
      .subscribe(() => {
        // when user logs out from the cart (excluding the first step), navigate to the homepage
        // if is auction cart and user is logged out return to homepage
        if (!this.isStep(CartStep.PREVIEW) || this.isAuctionCart()) {
          void this.router.navigate(['/']);
        }
      });

    this.itemToUpdate
      .pipe(
        mergeMap((cartItem: CartItemFormDto) => this.cartApiService.insertCartItem$({ cartItemFormDto: cartItem })
          .pipe(
            mergeMap((cartOverview: CartOverviewDto) => zip(
              of(cartOverview),
              this.cartApiService.showCartDetail$(),
            )),
            catchError((error: HttpError, caught: Observable<unknown>) => {
              if (error?.body?.errors?.some((e: DefaultMessageSourceResolvable) => e.code === 'validation.cart.purchase.limit')) {
                this.reloadCart();
                this.toastService.showDanger({ key: 'SHOPPING_CART_PURCHASE_LIMIT_ADD_ANOTHER_WARNING_MESSAGE' });
              }
              return of(null);
            }),
            finalize(() => this.cartState.quantityChangeInProgress = false),
          ),
        ),
      )
      .subscribe(([cartOverview, cartDetail]) => {
        this.cartOverview.next(cartOverview);
        this.cartState.cartDetail = cartDetail;
        this.cartState.cartCheckout = null; // clear delivery options so user cannot proceed to next steps
      });

    // load cart at startup
    this.loadCartOverview();

    this.initCartResetOnUserCurrencyChange();

  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    void this.inAppBrowserUrlChangeEvent?.remove();
  }

  private initCartResetOnUserCurrencyChange(): void {
    this.userCurrencyPreferenceService.preferredCurrencyCodeChanged$
      .pipe(
        tap(() => this.resetUserCartData()),
        filter(() => this.isCart()),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        // return to first cart step if not already there
        if (!this.isStep(CartStep.PREVIEW)) {
          void this.router.navigate(['/kosik']);
        }
      });
  }

  // TODO(PDEV-10719) Evaluate and remove empty pickup point logging
  public addPickupPointLog(log: string): void {
    this.emptyPickupPointLogs.push(log);
  }

  public isCart(): boolean {
    return this.isBuyNowCart() || this.isAuctionCart();
  }

  public isAuctionCart(): boolean {
    return this.router.url.startsWith('/zaplatit');
  }

  public isBuyNowCart(): boolean {
    return this.router.url.startsWith('/kosik');
  }

  // TODO(PDEV-10719) Evaluate and remove empty pickup point logging
  public getEmptyPickupPointLogs(): string {
    return this.emptyPickupPointLogs.join('\r\n');
  }

  public getCartOverview(): Observable<CartOverviewDto> {
    return this.cartOverview.asObservable();
  }

  private getCartOverviewValue(): CartOverviewDto {
    return this.cartOverview.getValue();
  }

  public setCartOverviewValue(cartOverview): void {
    this.cartOverview.next(cartOverview);
  }

  public reloadCart(): void {
    this.loadCartOverview();
    this.loadCartDetail()
      .pipe(
        finalize(() => this.cartState.previewLoading = false),
        take(1),
      )
      .subscribe(
        null,
        (error: HttpError | Error) => this.handleCartError(error),
      );
  }

  public loadCartOverview(): void {
    if (this.platformService.isBot) {
      return;
    }

    this.cartApiService.showCart$(null)
      .pipe(
        take(1),
        catchError((error: HttpError | Error) => {
          if (!(error instanceof Error)) {
            this.tryHandleCookieCartError(error);
          }
          return throwError(error);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((cartOverview: CartOverviewDto) => this.cartOverview.next(cartOverview));
  }

  public loadCartDetail(): Observable<CartDetailDto> {
    this.cartState.cartType = CartType.COMMON;
    return this.loadDetail(this.cartApiService.showCartDetail$());
  }

  public loadAuctionCartDetail(params: AuctionCartContentDto): Observable<CartDetailDto> {
    this.cartState.cartType = CartType.AUCTION;
    return this.loadDetail(this.cartAuctionService.cartDetail$({ auctionCartContentDto: params }));
  }

  public orderCartItems(cartItemIds: number[]): Observable<void> {
    this.cartState.selectedCartItemIds = cartItemIds;
    return this.getOptionsChoice(this.cartApiService.showCartOptionsChoice$({ cartItemIds }));
  }

  public orderAuctionCartItems(cartItemIds: number[]): Observable<void> {
    const optionsChoiceRequest = this.cartAuctionService.showCartOptionsChoice1$({
      cartId: this.cartState.cartDetail.cart.cartId,
      cartItemIds,
    });
    return this.getOptionsChoice(optionsChoiceRequest);
  }

  public orderFromSeller(sellerId: number, currency: CurrencyCode): number[] {
    let cartItemIds: number[] = [];
    for (const seller of this.cartState.cartDetail.cartItemsBySeller) {
      if (seller.sellerId === sellerId && seller.currency === currency) {
        cartItemIds = seller.cartItemsDetailDto.map((item) => item.cartItemId);
      }
    }
    return cartItemIds;
  }

  public orderAll(): number[] {
    let cartItemIds: number[] = [];
    for (const seller of this.cartState.cartDetail.cartItemsBySeller) {
      cartItemIds = [...cartItemIds, ...seller.cartItemsDetailDto.map((item) => item.cartItemId)];
    }
    return cartItemIds;
  }

  public updateItem(itemId: number, quantity: number): void {
    this.cartState.quantityChangeInProgress = true;

    const data: CartItemFormDto = {
      item: { itemId, itemQuantity: quantity },
      update: true,
    };

    this.itemToUpdate.next(data);

    const cartOverview: CartOverviewDto = {
      ...this.getCartOverviewValue(),
      cartItems: this.getCartOverviewValue().cartItems.filter((item: CartItemOverviewDto) => item.itemId === itemId),
    };

    this.quantityUpdate.next({
      type: cartOverview.cartItems[0].quantity < quantity ? 'add' : 'remove',
      cartOverview,
      currentAmount: this.calculateQuantityDifference(cartOverview.cartItems[0].quantity, quantity),
    });
  }

  public removeItem(cartItemId: number): void {
    this.cartApiService.deleteCartItem$({ id: cartItemId })
      .pipe(
        finalize(() => this.reloadCart()),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          const cartOverview: CartOverviewDto = { ...this.getCartOverviewValue() };
          cartOverview.cartItems = cartOverview.cartItems.filter((item: CartItemOverviewDto) => item.cartItemId === cartItemId);

          void this.googleAnalyticsTrackingService.trackCartQuantity({
            type: 'remove',
            cartOverview,
            currentAmount: cartOverview.cartItems[0].quantity,
          });
        },
        error: (error: HttpError) => {
          this.toastService.showDanger({ key: 'CART_DELETE_ITEM_ERROR' });
          throw error;
        },
      });
  }

  public checkoutCommonCart(cartCheckoutFormDto: CartCheckoutFormDto): Observable<CartThankYouDto> {
    cartCheckoutFormDto.gaClientInformationDto = this.gaClientInformationService.getGaClientInformation();

    // TODO(PDEV-10719) Evaluate and remove empty pickup point logging
    this.logPickupPointIfNeeded(cartCheckoutFormDto);

    return this.checkout(this.cartApiService.checkoutCart1$({ cartCheckoutFormDto }));
  }

  public checkoutAuctionCart(cartCheckoutFormDto: CartCheckoutFormDto): Observable<CartThankYouDto> {
    const request = this.cartAuctionService.checkoutCart$({
      cartId: this.cartState.cartDetail.cart.cartId,
      cartCheckoutFormDto,
    });

    // TODO(PDEV-10719) Evaluate and remove empty pickup point logging
    this.logPickupPointIfNeeded(cartCheckoutFormDto);

    return this.checkout(request);
  }

  // TODO(PDEV-10719) Evaluate and remove empty pickup point logging
  /**
   * @param cartCheckoutFormDto - cart info
   * @returns boolean - TRUE if some shipping method has pickup point and pickup point has empty ID
   */
  private logPickupPointIfNeeded(cartCheckoutFormDto: CartCheckoutFormDto): boolean {
    if (isNil(cartCheckoutFormDto?.sellerPayments)) {
      return false;
    }

    const isBalikovnaOrZasilkovnaWithoutPickupPoint: boolean = cartCheckoutFormDto.sellerPayments
      .some((sellerPayment: CartCheckoutSellerFormDto) => sellerPayment.selectedCartItemShippingMethods
        .some((method: SelectedCartItemShippingDto) => {
          const isBalikovnaWithoutPP =
            BalikovnaWidgetHelper.isBalikovnaAndHasEmptyPickupPoint(method.shippingOptionId, method.pickupPoint);
          const isZasilkovnaWithoutPP =
            ZasilkovnaWidgetHelper.isZasilkovnaAndHasEmptyPickupPoint(method.shippingOptionId, method.pickupPoint);
          return isBalikovnaWithoutPP || isZasilkovnaWithoutPP;
        }));

    if (!isBalikovnaOrZasilkovnaWithoutPickupPoint) {
      return;
    }

    this.addPickupPointLog(`Trying to checkout cart with empty pickup point cartDetail=[${ JSON.stringify(this.cartState) }]`);

    withScope((scope: Scope) => {
      scope.setExtra('error', this.getEmptyPickupPointLogs());
      captureException(new Error('Trying to checkout cart with empty pickup point!'));
    });
  }

  /**
   * Init card payment on BE, and redirect to card payment gateway (redirectUrl)
   * @param paymentViaAukroId
   */
  public initPaymentViaAukroAndRedirect(paymentViaAukroId: number): void {
    this.cardPaymentService.createPayment$({
      createCardPaymentDto: {
        pvaId: paymentViaAukroId,
        paymentGateway: 'BARION',
        cardPaymentChannel: 'CARD_PAYMENT',
        android: PlatformCommonService.isNativeAndroidApp,
      },
    })
      .pipe(
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response: CardPaymentCreatedDto) => {
          if (response?.redirectUrl) {
            if (PlatformCommonService.isNativeAndroidApp) {
              this.window.open(response?.redirectUrl, '_blank');
            } else {
              this.window.location.href = response?.redirectUrl;
            }
          } else {
            void this.router.navigate(['/404']);
          }
        },
        error: (error: HttpError) => {
          void this.router.navigate(['/500']);
          throw error;
        },
      });
  }

  public setStep(step: CartStep): void {
    this.step.next(step);
  }

  public getStep(): Observable<CartStep> {
    return this.step.asObservable();
  }

  public isStep(step: CartStep): boolean {
    return this.step.value === step;
  }

  public setShippingValid(valid: boolean): void {
    this.cartState.shippingValid = valid;
  }

  public truncateSavedCartData(): void {
    this.localStorageService.removeItem(PAYU_BANK);

    // Remove stored deal IDs from the first auction cart step.
    this.storedDealIds = [];
  }

  public getCartItemIds(cartState: CartState): number[] {
    const ids: number[] = [];
    cartState.cartDetail.cartItemsBySeller.forEach((sellerItem: CartItemsBySellerDto) => {
      sellerItem.cartItemsDetailDto.forEach((item: CartItemDetailDto) => {
        ids.push(item.itemId);
      });
    });
    return ids;
  }

  /**
   * Return bought items, usable for all payments method including PayU!!!
   * @returns
   */
  public getBoughtItems(): BoughtItemExtendedDto[] {
    const boughtItems: BoughtItemExtendedDto[] = [];
    this.cartState.cartThankYou.sellers.forEach((cartSeller: CartThankYouSellerDto) => {
      cartSeller.boughtItems.forEach((boughtItem: BoughtItemDto) => {
        boughtItems.push({
          ...boughtItem,
          unitPrice: MoneyCalcUtils.div(boughtItem.itemPrice, boughtItem.itemQuantity),
          sellerId: cartSeller.seller?.userId,
        });
      });
    });
    return boughtItems;
  }

  public reloadUserAddresses(): void {
    this.cartState.addressesReloading = true;
    this.cartApiService.showCartOptionsChoice$({ cartItemIds: this.cartState.selectedCartItemIds })
      .pipe(
        finalize(() => this.cartState.addressesReloading = false),
        take(1),
      )
      .subscribe(
        (cartCheckout: CartCheckoutDto) => {
          this.cartState.cartCheckout = cartCheckout;
          this.cartState.cartCheckoutForm.deliveryAddressId = cartCheckout.userAddresses && cartCheckout.userAddresses.length > 0
            ? cartCheckout.userAddresses[0].id
            : null;
          this.cartState.cartCheckoutForm.deliveryAddressManual = null;
        },
        (error: HttpError) => {
          if (this.isUserBlockedInResponse(error)) {
            void this.router.navigate(['/kosik']);
          } else {
            this.handleCartError(error);
          }
        },
      );
  }

  public isUserBlockedInResponse(e: HttpError): boolean {
    if (isNil(JSON.stringify(e?.body))) {
      return false;
    }
    return JSON.stringify(e.body).indexOf('NOT_ALLOWED_TO_BUY') !== -1;
  }

  // common for cartOptionsChoice and checkoutCart
  public handleCartError(error: HttpError | Error): string {
    if (error instanceof Error) {
      throw error;
    }

    if (ArrayUtils.isNotEmpty(error?.body?.errors)) {
      if (error.body.errors.some((item: DefaultMessageSourceResolvable) => item.code === 'validation.item.shopping.right')) {
        return this.errorMessages['NOT_ALLOWED_TO_BUY'];
      }

      if (error.body.errors.some((item: DefaultMessageSourceResolvable) =>
        item.code === 'validation.message.shippingMethod.missingPickupPoint')
      ) {
        return this.errorMessages['REQUIRED_BUT_MISSING_PICKUP_POINT'];
      }

      if (error.body.errors.some((e: DefaultMessageSourceResolvable) => e.code === 'validation.cart.purchase.limit')) {
        return this.translateService.instant('SHOPPING_CART_PURCHASE_LIMIT_ADD_ANOTHER_WARNING_BANNER') as string;
      }

      if (error.body.errors.some((e: DefaultMessageSourceResolvable) => e.code.endsWith('firstName.notAllowed'))) {
        return this.errorMessages['FIRSTNAME_NOT_ALLOWED_HTML'];
      }
      if (error.body.errors.some((e: DefaultMessageSourceResolvable) => e.code.endsWith('lastName.notAllowed'))) {
        return this.errorMessages['LASTNAME_NOT_ALLOWED_HTML'];
      }

      const phoneNumberValidationErrors = ['empty', 'parseError', 'phoneNumberNotExists', 'phoneNumberNotValid'];
      if (error.body.errors.some((item: DefaultMessageSourceResolvable) =>
        phoneNumberValidationErrors.includes(item?.code))
      ) {
        const errorArguments = error.body.errors.flatMap(item => item.arguments);
        if (errorArguments.some(arg => arg === BalikovnaShippingCodeEnum.AUKRO_BALIKOVNA
          || arg === BalikovnaShippingCodeEnum.AUKRO_BALIKOVNA_ADDRESS)
        ) {
          return this.errorMessages['INVALID_PHONE_NUMBER_BALIKOVNA'];
        } else if (
          errorArguments.some(arg => arg === ZasilkovnaShippingCodeEnum.AUKRO_ZASILKOVNA
            || arg === ZasilkovnaShippingCodeEnum.AUKRO_ZASILKOVNA_ADDRESS)
        ) {
          return this.errorMessages['INVALID_PHONE_NUMBER_ZASILKOVNA'];
        } else {
          return this.errorMessages['INVALID_PHONE_NUMBER'];
        }
      }

      const hasCartItemValidations = error.body.errors.some((item: DefaultMessageSourceResolvable) =>
        item.code === 'checkoutCart.validation.validationFailed');
      if (hasCartItemValidations) {
        const cartItemsChanged: SellerCartItemsChangedResult = {
          results: [],
          resumeCart: true,
        };
        let deletedCartItemsCount: number = 0;

        error.body.errors[0].arguments.forEach((argument: CartItemValidation) => {
          // grouped by cartItemId
          Object.entries(argument).forEach(([cartItemId, validations]: [string, CartItemValidationError]) => {
            const convertedCartItemId: number = parseInt(cartItemId, 10);
            // validation key in object key
            Object.entries(validations).forEach(([validationKey]: [CartItemChangeType, DefaultMessageSourceResolvable]) => {
              if (['USER_IS_ON_BLACKLIST', 'USER_NOT_VERIFIED', 'UNREGISTERED_USER_CANT_PAY_VIA_AUKRO'].includes(validationKey)) {
                // if remains at least one good cart item, user can continue
                deletedCartItemsCount++;
              } else {
                // cart process must be stopped if exists at least one cart item validation but blacklisted
                cartItemsChanged.resumeCart = false;
              }
              const cartOverview: CartOverviewDto = this.cartOverview.value;
              const cartItemOverview: CartItemOverviewDto = cartOverview.cartItems.find((cartItem: CartItemOverviewDto) =>
                cartItem.cartItemId === convertedCartItemId);
              const itemDetailChanged: CartItemDetailDtoChanged = {
                ...cartItemOverview,
                reason: validationKey,
              } as CartItemDetailDtoChanged;
              this.addCartItemDetailToChangedResults(
                cartItemsChanged.results,
                itemDetailChanged,
                cartItemOverview.sellerId,
                cartItemOverview.sellerLogin,
                cartItemOverview.seller,
              );
            });
          });
        });

        // do not resume cart when all cart items are blacklisted
        if (
          cartItemsChanged.resumeCart
          && (
            !this.cartState.selectedCartItemIds
            || (this.cartState.selectedCartItemIds && this.cartState.selectedCartItemIds.length === deletedCartItemsCount)
          )
        ) {
          cartItemsChanged.resumeCart = false;
        }

        this.cartItemsChanged.next(cartItemsChanged);

        return null;
      }
    } else if (error?.body?.fields?.some((item: FieldError) => item?.field === 'deliveryAddressManual')) {
      return this.errorMessages['MISSING_DELIVERY_ADDRESS'];
    }

    // Tries to handle cart related error, false if no such error occured
    if (this.tryHandleCookieCartError(error)) {
      return 'ATTEMPTED_COOKIE_FIX';
    }

    // log unhandled error to sentry
    withScope((scope: Scope) => {
      scope.setExtra('error', error);
      captureException(new Error('Unhandled cart error with message GENERAL_CART_ERROR'));
    });
    return this.errorMessages['GENERAL_CART_ERROR'];
  }

  private tryHandleCookieCartError(error: HttpError): boolean {
    if (error?.body?.fields?.some((item: FieldError) => item?.field === CartConstants.COOKIE_CART_NAME)) {
      withScope((scope: Scope) => {
        scope.setExtra('error', error);
        scope.setExtra(CartConstants.COOKIE_CART_NAME, this.cookieService.get(CartConstants.COOKIE_CART_NAME));
        captureException('Cookie related cart error', { fingerprint: ['cart-service-cookie'] });
        console.log('Cookie related cart error');
      });

      if (CookieUtil.shouldBeNumberValSanitized(this.cookieService.get(CartConstants.COOKIE_CART_NAME))) {
        this.cookieService.remove(CartConstants.COOKIE_CART_NAME);
      }
      return true;
    }
    return false;
  }

  public addCartItemDetailToChangedResults(
    results: SellerCartItemsChanged[],
    cartItemDetail: CartItemDetailDtoChanged,
    sellerId: number,
    sellerLogin: string,
    seller: UserChipSmallDto): void {
    let sellerIndex: number = results.findIndex((seller2: SellerCartItemsChanged) => seller2.sellerId === sellerId);
    if (sellerIndex === -1) {
      results.push({
        sellerId,
        sellerLogin,
        cartItemsDetailDto: [],
        seller,
      });
      sellerIndex = results.length - 1;
    }

    results[sellerIndex].cartItemsDetailDto.push(cartItemDetail);
  }

  private resetUserCartData(): void {
    this.cartState.cartCheckout = null;
    this.cartState.cartCheckoutForm = null;
    this.cartState.cartThankYou = null;
  }

  private loadDetail(request: Observable<CartDetailDto>): Observable<CartDetailDto> {
    const loadDetailRequest = of(null)
      .pipe(
        mergeMap(() => request.pipe(tap(() => this.cleanNextStepsState()),
          catchError((error) => {
            this.cartState.cartDetail = null;
            this.cleanNextStepsState();
            return throwError(error);
          }),
          tap((cartDetail: CartDetailDto) => {
            this.cartState.cartDetail = cartDetail;
          })),
        ),
      );
    return zip(
      loadDetailRequest,
      this.registryService.getListItemQuantityEnum(),
      (detail: CartDetailDto, quantityTypes: RegistryItemDto[]) => {
        this.cartState.quantityTypes = quantityTypes;
        return detail;
      });
  }

  // cannot be in finalize operator because of possible slow network, then it can crash guard-check on navigation back
  private cleanNextStepsState(): void {
    this.cartState.cartCheckout = null; // clear delivery options so user cannot proceed to next steps
    this.cartState.shippingValid = false; // set validity to false so user cannot proceed to the third step
    this.cartState.cartThankYou = null; // clear thank you data so user cannot go to thank you page
  }

  private getOptionsChoice(optionsChoiceRequest: Observable<CartCheckoutDto>): Observable<void> {
    return this.translateService.get(['PAYMENT_VIA_AUKRO_BANK_TRANSFER_OPTION_CART_LABEL', 'PAYMENT_CARD'])
      .pipe(
        mergeMap((translations) => zip(
          optionsChoiceRequest,
          this.loadPaymentMethods(),
          this.registryService.getEUCountries(),
          this.paymentService.getPayUPaymentMethods(),
          (cartCheckout: CartCheckoutDto,
            paymentMethods: RegistryItemDto[],
            countries: RegistryCountryItemDto[],
            payUBanks: PayByLinkDto[]) => {
            this.cartState.cartCheckout = cartCheckout;
            this.cartState.cartCheckoutForm = this.getInitialCartCheckoutForm(cartCheckout);
            this.cartState.paymentMethods = paymentMethods;
            this.cartState.countries = countries;
            this.cartState.payUMethods = payUBanks;
            this.cartState.paysMethods = [
              {
                name: translations['PAYMENT_CARD'],
                code: 'PAYMENT_CARD',
              },
              {
                name: translations['PAYMENT_VIA_AUKRO_BANK_TRANSFER_OPTION_CART_LABEL'],
                code: 'BANK_TRANSFER',
              }];
          })),
      );
  }

  private getInitialCartCheckoutForm(cartCheckout: CartCheckoutDto): CartCheckoutFormDto {
    const sellerPayments = this.mergeSellerPayments(cartCheckout);
    const { deliveryAddressId, deliveryAddressManual } = this.mergeAddress(cartCheckout);

    return { sellerPayments, deliveryAddressId, deliveryAddressManual };
  }

  private mergeSellerPayments(cartCheckout: CartCheckoutDto): CartCheckoutSellerFormDto[] {
    const sellerPayments = cartCheckout.sellerOptions.map((sellerOption: CartCheckoutOptionsDto) => ({
      sellerId: sellerOption.sellerId,
      selectedCartItemShippingMethods: sellerOption.groupedByShippingOptions.map((item: ItemShippingOptionsCombinationDto) => ({
        cartItemIds: item.items.map((itemInGroup: CartCheckoutItemDto) => itemInGroup.cartItemId),
        shippingOptionId: null,
        paymentMethodId: null,
        paymentViaAukroMethodEnum: null,
        note: null,
        pickupPoint: null,
      })) as SelectedCartItemShippingDto[],
    }));

    // fill selected shipping and payment from FE storage
    if (this.cartState.cartCheckoutForm && this.cartState.cartCheckoutForm.sellerPayments) {
      const oldSellers = this.cartState.cartCheckoutForm.sellerPayments;
      // iterate by seller
      sellerPayments.forEach((newSeller: CartCheckoutSellerFormDto) => {
        // find old data for current seller
        const oldSeller = oldSellers.find((item: CartCheckoutSellerFormDto) => item.sellerId === newSeller.sellerId);
        if (oldSeller) {
          // find options for current seller
          const newSellerOptions = cartCheckout.sellerOptions.find((item: CartCheckoutOptionsDto) => item.sellerId === newSeller.sellerId);
          // iterate current selection groups for seller
          newSeller.selectedCartItemShippingMethods.forEach((selectionGroup: SelectedCartItemShippingDto, selectionIndex: number) => {
            const newCartItemsSortedString: string = this.createCartItemsString(selectionGroup.cartItemIds);
            const oldSelectionGroup = oldSeller.selectedCartItemShippingMethods
              .find((item: SelectedCartItemShippingDto) => this.createCartItemsString(item.cartItemIds) === newCartItemsSortedString);
            // continue if there is exactly same group in old groups
            if (oldSelectionGroup) {
              // get shipping and payment options for selection group
              const groupOptions = newSellerOptions.groupedByShippingOptions[selectionIndex];
              // find old shipping option in current shipping options
              const shippingOption = groupOptions.shippingOptions.find((item: CartCheckoutShippingOptionDto) =>
                item.shippingMethodId === oldSelectionGroup.shippingOptionId);
              if (shippingOption) {
                selectionGroup.shippingOptionId = oldSelectionGroup.shippingOptionId;
                selectionGroup.pickupPoint = oldSelectionGroup.pickupPoint;
              }
            }
          });
        }
      });
    }

    return sellerPayments;
  }

  private mergeAddress(cartCheckout: CartCheckoutDto): CartMergedAddresses {
    const emptyManualAddress: CartCheckoutAddressFormDto = {
      address: '',
      city: '',
      company: '', // optional
      countryId: DomainUtils.getCountryIdByDomainCode(this.domainService.domain),
      email: '', // only for unregistered user
      firstName: '', // optional
      lastName: '', // optional
      phone: '',
      zipCode: '',
    };
    // set default params
    let deliveryAddressId = cartCheckout.userAddresses && cartCheckout.userAddresses.length > 0 ? cartCheckout.userAddresses[0].id : null;
    let deliveryAddressManual = deliveryAddressId ? null : emptyManualAddress;

    // merge with existing state
    if (this.cartState.cartCheckoutForm) {
      if (this.cartState.cartCheckoutForm.deliveryAddressId) {
        const addressFound = cartCheckout.userAddresses?.find((item: AddressDto) =>
          item.id === this.cartState.cartCheckoutForm.deliveryAddressId);
        deliveryAddressId = addressFound ? this.cartState.cartCheckoutForm.deliveryAddressId : null;
      } else if (this.cartState.cartCheckoutForm.deliveryAddressManual) {
        deliveryAddressManual = this.cartState.cartCheckoutForm.deliveryAddressManual;
      }
    }
    return { deliveryAddressId, deliveryAddressManual };
  }

  private loadPaymentMethods(): Observable<RegistryItemDto[]> {
    return this.registryApiService.getPaymentMethods$();
  }

  private checkout(request: Observable<CartThankYouDto>): Observable<CartThankYouDto> {
    this.cartState.orderInProgress = true;
    return request
      .pipe(
        tap((cartThankYou: CartThankYouDto) => {
          this.cartState.cartThankYou = cartThankYou;
          this.crossTabMessagingService.postMessage({ type: 'CART_CHECKOUT' });
          this.collectedEmail = null;
          this.loadCartOverview();

          void this.router.navigate(['/kosik/objednavka-odeslana']);
        }),
        finalize(() => this.cartState.orderInProgress = false),
      );
  }

  /**
   * A value is emitted whenever login status is changed. True is emitted when user logs in the second step of the
   *  cart, false otherwise.
   */
  private loginStatusChangedInSecondStepAfterLogin(): Observable<boolean> {
    return this.authService.getLoginStatusChange()
      .pipe(map((loggedIn) =>
        loggedIn // when user has logged in
        && this.cartState.cartType === CartType.COMMON // only for ordinary cart
        && this.isStep(CartStep.SHIPPING_AND_PAYMENT), // when cart is in the second step
      ));
  }

  private calculateQuantityDifference(currentQuantity: number, newQuantity: number): number {
    let quantityChange = newQuantity - currentQuantity;
    if (quantityChange < 0) {
      quantityChange = quantityChange * -1;
    }

    return quantityChange;
  }

  private createCartItemsString(numbers: number[]): string {
    return numbers.sort((a, b) => a - b).join('-'); // sort asc
  }

  public getItemQuantityInCart(itemId): number {
    return this.getCartOverviewValue().cartItems.find((cartItem: CartItemOverviewDto) => cartItem.itemId === itemId)?.quantity;
  }

  public makePaymentForPva(pvaId: number): void {
    if (isNil(pvaId)) {
      return;
    }

    void this.router.navigate(['/kosik', 'platebni-brana', pvaId]);
  }

  public saveEmail(emailDto: EmailDto): Observable<void> {
    if (isNil(emailDto)) {
      return of(null);
    }

    return this.cartApiService.saveCartEmail$({ emailDto });
  }

  /**
   * Checks if cart item has transaction fee
   */
  public hasTransactionFee(
    transactionFee: MoneyDto,
    buyerProtectionSelected: boolean,
    isShippingPaymentBefore: boolean,
  ): boolean {
    return isNotNil(transactionFee)
      && transactionFee.amount > 0
      && !buyerProtectionSelected
      && isShippingPaymentBefore;
  }

}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import isNil from 'lodash-es/isNil';
import { WITH_CREDENTIALS } from '@shared/rest/token/with-credentials-http-context.token';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { environment } from '@environments';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private readonly authCacheService: AuthCacheService,
    private readonly authenticationService: AuthenticationService,
  ) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return of(req)
      .pipe(
        mergeMap(() => next.handle(req.clone({ setHeaders: this.getAuthHeaders(req) }))
          .pipe(
            catchError((e) => this.catchRequestError(e, req)),
          ),
        ),
      );
  }

  private getAuthHeaders(req: HttpRequest<unknown>): Record<string, string> {
    const headers: { [key: string]: string } = {};

    if (req.context.has(WITH_CREDENTIALS) && req.context.get(WITH_CREDENTIALS) === false) {
      return headers;
    }

    const authToken: string = this.getAuthorizationToken();
    if (authToken) {
      headers.Authorization = authToken;
    }

    const aukroToken: string = this.authCacheService.aukroToken;
    if (aukroToken) {
      headers['X-Aukro-Token'] = aukroToken;
    }

    /**
     * This token is used to check for Native app in BE, so we can make an exception that will
     * not block requests from foreign countries.
     */
    if (PlatformCommonService.isNativeApp) {
      headers['X-Aukro-App'] = environment.MOBAPP_TEST_IDENTIFIER;
    }

    return headers;
  }

  private getAuthorizationToken(): string {
    const token = this.authCacheService.authToken;
    if (!token) {
      return null;
    }

    if (!this.authenticationService.isLoggedIn()) {
      this.authenticationService.logout();

      return null;
    }

    return token;
  }

  private catchRequestError(err: HttpErrorResponse, req: HttpRequest<unknown>): Observable<never> {
    if (isNil(req.context.get(USER_ACTION_TYPE))) {
      this.authenticationService.logoutOnAuthError(err);
    }

    return throwError(() => err);
  }

}

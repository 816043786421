import { Inject, Injectable } from '@angular/core';
import { AppTrackingTransparency } from 'capacitor-ios-app-tracking';
import { WINDOW_OBJECT } from '@util/const/window-object';

@Injectable({
  providedIn: 'root',
})
export class TransparencyService {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
  ) { }

  public async transparencyIosRequest(): Promise<boolean> {
    const appTrackingStatus = await AppTrackingTransparency.getTrackingStatus();
    if (appTrackingStatus.status === 'authorized') {
      return Promise.resolve(true);
    }
    if (appTrackingStatus.status !== 'unrequested') {
      return Promise.resolve(false);
    }

    const responseTracking = await AppTrackingTransparency.requestPermission();

    if (responseTracking.status === 'authorized') {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

}

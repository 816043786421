import isArray from 'lodash-es/isArray';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { IdAware } from '@util/helper-types/id-aware';

export class ArrayUtils {

  public static isArray<T>(arrayLike: unknown): arrayLike is T[] {
    return isArray(arrayLike);
  }

  public static isEmpty(array: unknown[] | Nil): array is unknown[] & boolean {
    if (!isArray(array)) {
      return true;
    }

    return !array.length;
  }

  public static isNotEmpty<T>(data: T[] | Nil): boolean {
    return !ArrayUtils.isEmpty(data);
  }

  public static findDuplicates(arr: unknown[]): unknown[] {
    if (ArrayUtils.isEmpty(arr) || arr.length === 1) {
      return [];
    }

    return arr.filter((element, i) => arr.indexOf(element) !== i);
  }

  /**
   * Returns true, if array have same values and length, otherwise false
   * @param array1 - First array to compare
   * @param array2 - Second array to compare
   */
  public static hasSameLengthAndValues(array1: string[], array2: string[]): boolean {
    return array1?.every((prerequisite) => array2?.includes(prerequisite))
      && array1?.length === array2?.length;
  }

  public static insertItemAtIndexIfIndexExists<ARRAY_ITEM>(
    array: ARRAY_ITEM[],
    insertAtIndex: number,
    itemToInsert: ARRAY_ITEM,
  ): ARRAY_ITEM[] {
    if (
      ArrayUtils.isEmpty(array)
      || isNil(itemToInsert)
      || isNil(insertAtIndex)
      || insertAtIndex < 0
      || insertAtIndex > array.length
    ) {
      return array;
    }

    array.splice(insertAtIndex, 0, itemToInsert);

    return array;
  }

  public static withReplacedItem<T extends IdAware>(array: T[] | Nil, itemToReplace: T | Nil): T[] | Nil {
    if (ArrayUtils.isEmpty(array) || isNil(itemToReplace)) {
      return array;
    }

    const arrayCopy: T[] = [...array];
    const index: number = array.findIndex((item: T) => item.id === itemToReplace.id);

    if (index !== -1) {
      arrayCopy[index] = itemToReplace;
    }

    return arrayCopy;
  }

  public static withAddedItem<T>(array: T[] | Nil, item: T | Nil): T[] {
    if (!isArray(array)) {
      return [item];
    }

    return [...array, item];
  }

  public static withRemovedItem<T>(array: T[] | Nil, item: T | Nil): T[] | Nil {
    if (ArrayUtils.isEmpty(array)) {
      return array;
    }

    const arrayCopy: T[] = [...array];
    const index: number = array.indexOf(item);

    if (index !== -1) {
      arrayCopy.splice(index, 1);
    }

    return arrayCopy;
  }

}

import { Injectable } from '@angular/core';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { TranslationService } from '@api/generated/api/Translation';
import { Observable, of } from 'rxjs';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CacheScope } from '@common/cache/model/cache-scope';
import { DateUtils } from '@util/util/date.utils';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { captureException, Scope, withScope } from '@sentry/browser';
import { TranslationValueDto } from '@api/generated/defs/TranslationValueDto';
import { StringUtils } from '@util/util/string.utils';

@Injectable({
  providedIn: 'root',
})
export class TranslationCacheService implements CacheAware {

  public translationsLoaded: boolean = false;
  private TRANSLATIONS: Record<string, string> = {};

  constructor(
    private readonly translationService: TranslationService,
    public readonly cacheService: CacheService,
  ) {
  }

  @Cacheable({
    cacheScope: CacheScope.PROCESS,
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(5),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(15),
    key: 'TranslationCacheService#getAll',
    localeDependent: true,
  })
  public getAll(): Observable<Record<string, string>> {
    return this.translationService.getAllTranslations()
      .pipe(
        catchError((err: unknown) => {
          withScope((scope: Scope) => {
            const title: string = 'TranslationService: Cannot load translations from endpoint';
            scope.setFingerprint([title]);
            scope.setExtra('HttpError', err);
            captureException(new Error(title));
          });
          return of([]);
        }),
        filter((translations: TranslationValueDto[]) => translations !== null),
        tap((data) => {
          if (data) {
            this.translationsLoaded = true;
          }
        }),
        map((data) => {
          if (data) {
            this.TRANSLATIONS = this.mapTranslations(data);
          }
          return this.TRANSLATIONS;
        }),
      );
  }

  /**
   * Check if translations file contains given key, its value is not important
   * @param key Translation key
   */
  public hasTranslation(key: string): boolean {
    return this.TRANSLATIONS[key] !== undefined && !StringUtils.isEmpty(this.TRANSLATIONS[key]);
  }

  /** Returns the count of actual translations – only for logging purposes */
  public translationsCount(): number {
    return Object.keys(this.TRANSLATIONS).length;
  }

  public translationsContent(): Record<string, string> {
    return this.TRANSLATIONS;
  }

  /**
   * return translation in format {KEY: translation, KEY2: translation2, ...}
   * @param translationData
   */
  private mapTranslations(translationData: TranslationValueDto[]): Record<string, string> {
    const translationObj: Record<string, string> = {};
    translationData.forEach((translate: { key: string; value: string }) => {
      translationObj[translate.key] = translate.value;
    });
    return translationObj;
  }

}

import { Injectable } from '@angular/core';
import filter from 'lodash-es/filter';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PayUPaymentService } from '@api/generated/api/PayUPayment';
import { ConfigElementValueDto, PayByLinkDto, PaymentMethodsDto } from '@api/generated/model';
import { PAYU_CANCELLED_CE_NAME, PvaState, PvaStateGlobal, ViewerType } from '../../../../typings/original/internal';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { StringUtils } from '@util/util/string.utils';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CacheScope } from '@common/cache/model/cache-scope';
import { DateUtils } from '@util/util/date.utils';

@Injectable({
  providedIn: 'root',
})
export class PaymentService implements CacheAware {

  constructor(private readonly configuratorCacheService: ConfiguratorCacheService,
              private readonly payUPaymentService: PayUPaymentService,
              public readonly cacheService: CacheService) {
  }

  public getPayUPaymentMethods(): Observable<PayByLinkDto[]> {
    return this.payUPaymentService.paymentMethods({}).pipe(
      map((result: PaymentMethodsDto) => result.payByLinks),
      map((payByLinks: PayByLinkDto[]) => filter(payByLinks, { status: 'ENABLED' })));
  }

  /**
   * @param payByLinks
   * @returns only enabled payment methods
   */
  public getEnabledBankTransferPaymentMethods(payByLinks: PayByLinkDto[]): PayByLinkDto[] {
    return payByLinks.filter((payByLink: PayByLinkDto) => payByLink.status === 'ENABLED');
  }

  /** @returns whether PayU features should be hidden */
  public payuCancelled(): Observable<boolean> {
    return this.configuratorCacheService.systemParametersFE([PAYU_CANCELLED_CE_NAME])
      .pipe(
        map((configElement: ConfigElementValueDto[]) => configElement.length > 0 && StringUtils.parseBoolean(configElement[0].value)),
      );
  }

  /**
   * Returns PVA states information stored in system parameter config element.
   * @param pvaStateViewer
   */
  @Cacheable({
    cacheScope: CacheScope.PROCESS,
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(10),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(10),
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    keyResolver: (viewerType) => `PaymentService#getPvaStates#pvaStateViewer-${ viewerType }`,
  })
  public getPvaStates(pvaStateViewer: ViewerType): Observable<PvaState[]> {
    return this.configuratorCacheService.enumsContent<PvaStateGlobal>('PVA_STATES2')
      .pipe(
        take(1),
        // Retrieve only active states
        map((pvaStatesGlobal: PvaStateGlobal[]) => pvaStatesGlobal.filter(state => state.active)),
        // Postprocessing configuration object into objects used in FE app
        map((pvaStatesGlobal: PvaStateGlobal[]) => {
          const pvaStates: PvaState[] = [];

          pvaStatesGlobal.forEach((state: PvaStateGlobal) => {

            const filterStates = [];
            // In case that this state is grouped by with some previous one we do not want to filter by this state,
            // we want to use parent state to filter this state too
            if (state.groupedByWithState) {

              const parentState: PvaState = pvaStates.find((pvaState: PvaStateGlobal) => pvaState.code === state.groupedByWithState);

              if (parentState) {
                parentState.filterStates.push(state.code);
              }
            } else {
              // if there is grouping, state should filter itself (and later one there can be added another states)
              filterStates.push(state.code);
            }

            let name: string;
            let tooltip: string;
            switch (pvaStateViewer) {
              case ViewerType.BUYER:
                name = state.nameForBuyer;
                tooltip = state.tooltipForBuyer;
                break;
              case ViewerType.SELLER:
                name = state.nameForSeller;
                tooltip = state.tooltipForSeller;
                break;
              default:
                name = state.name;
            }

            const result: PvaState = {
              id: state.id,
              code: state.code,
              name,
              tooltip,
              filterStates,
            };
            pvaStates.push(result);
          });

          return pvaStates;
        }),
      );
  }

}

<div
  #searchDropdown="dialogDropdownExport"
  class="header-search-container lg:tw-max-w-xl tw-w-full"
  [ngClass]="{'tw-mb-1.5 tw-border-solid': !isMdAndLower}"
  (click)="clickAction.emit()"
  aukDialogDropdown
  [disableBackgroundScroll]="isMdAndLower && true"
  [autoClose]="!isMdAndLower"
  [disableClickToggle]="true"
  [closeFromOutside]="!isMdAndLower"
  [closeOnTouchOutside]="closeDropdownOnTouchOutside"
  (closeDropdown)="onDropdownClose()"
  (toggleChange)="dropdownToggled($event)"
  [dialogModel]="dropdownDialogModel"
  >
  <div class="tw-w-full tw-relative md:tw-mb-4 lg:tw-mb-0">
    @if (!isMdAndLower) {
      <auk-app-header-search-input
        (submitSearch)="submit($event)"
        (inputFocus)="loadAndShowSuggestions()"
        (inputClick)="inputSearchClick($event)"
        (inputKeydown)="handleKeyboardActions($event)"
        (inputType)="handleSuggestions()"
        (clearText)="clearSearchText()"
        (categoryChange)="onCatalogChange($event)"
        (searchRef)="setCompactSearchRef($event)"
        [searchCategory]="searchCategory"
        [searchCategories]="searchCategories"
      ></auk-app-header-search-input>
    }

    <!-- DROPDOWN -->
    <div
      class="tw-w-full tw-overflow-auto tw-bg-contrast tw-absolute tw-left-0 tw-border tw-border-surface-4 tw-shadow-type6 tw-rounded-lg tw-border-solid tw-right-0 tw-mt-2 tw-max-w-x sm:tw-left-2 sm:tw-right-auto sm:tw-border-silverstein-400 tw-shadow-2xl"
      [ngClass]="{
        '!tw-hidden' : !isDropdownVisible,
        '!tw-fixed !tw-z-[120] tw-h-screen tw-top-safe-top !tw-rounded-none !tw-mt-0' : isMdAndLower,
        'tw-rounded-[4px]' : !isPopularSearchVisible
         }"
      aukDropdownContainer
      >
      <!-- SEARCH COMPACT -->
      @if (isMdAndLower) {
        <auk-search-compact
          (handleKeyboardActions)="handleKeyboardActions($event)"
          (inputValueChanged)="onSearchCompactValueChanged()"
          (searchRef)="setCompactSearchRef($event)"
          (closeDropdown)="closeSuggestionsDropdown()"
          (searchClick)="submit()"
        ></auk-search-compact>
      }
      <!-- SEARCH COMPACT -->

      <!-- SEARCH CATEGORIES SELECT -->
      @if (isMdAndLower) {
        <div class="tw-flex tw-justify-between tw-items-center tw-border-b-surface-neutral-3 tw-pl-4 tw-pr-2 tw-py-2.5 tw-border-b tw-border-solid">
          <span class="tw-text-matter-neutral-secondary tw-text-xs tw-font-normal tw-uppercase">{{ 'MOBILE_SEARCH_MODAL_CATEGORIES' | translate }}</span>
          <auk-search-categories-list
            [categories]="searchCategories"
            [selectedCategory]="searchCategory"
            (categoryChange)="onCatalogChange($event)"
          ></auk-search-categories-list>
        </div>
      }
      <!-- ./SEARCH CATEGORIES SELECT -->

      <!-- POPULAR SEARCH -->
      @if (isPopularSearchVisible) {
        <auk-popular-search
          [popularSearchData]="popularSearchData"
          (popularSearchClick)="onPopularSearchClick($event)"
        ></auk-popular-search>
      }
      <!-- ./POPULAR SEARCH -->

      @if (!searchAlwaysInDescription) {
        <!-- EXTEND SEARCH FOR ITEM DESCRIPTION -->
        @if (!isUserSearch) {
          <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-pl-4 tw-pr-2 tw-py-2.5 tw-text-matter-neutral-secondary tw-text-xs tw-font-normal tw-leading-4 tw-w-full tw-uppercase tw-border-b-surface-neutral-4 tw-border-b tw-border-solid">
            {{ {key: 'HEADER_SEARCH_COMPONENT_HTML_1'} | translateSource }}

            @if (isMdAndLower) {
              <!-- DROPDOWN -->
              <div class="tw-border-0 tw-block tw-relative">
                <select class="tw-h-[26px] tw-text-matter-neutral-secondary tw-text-right tw-w-auto tw-justify-center tw-items-center tw-pr-8 tw-bg-transparent tw-text-ellipsis tw-border-0 tw-text-sm tw-bg-[url('/assets/icon/arrow-down-new.svg')] tw-bg-[length:0.5rem_0.5rem] tw-bg-[right_1rem_top_50%] focus:!tw-shadow-none"
                  [(ngModel)]="searchData.searchAll"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="searchAllChanged()">
                  <option value="true">{{ {key: 'HEADER_SEARCH_COMPONENT_HTML_1_YES'} | translateSource }}</option>
                  <option value="false">{{ {key: 'HEADER_SEARCH_COMPONENT_HTML_1_NO'} | translateSource }}</option>
                </select>
              </div>
              <!-- ./DROPDOWN -->
            } @else {
              <!-- CHECKBOX -->
              <input [(ngModel)]="searchData.searchAll"
                [ngModelOptions]="{ standalone: true }"
                type="checkbox"
                (ngModelChange)="searchAllChanged()"
                />
              <!-- ./CHECKBOX -->
            }
          </div>
        }
        <!-- ./EXTEND SEARCH FOR ITEM DESCRIPTION CHECKBOX -->
      }

      @if (searchResultItemsTotal || searchResultUsers) {
        <!-- LANDING PAGE AND CATEGORY SUGGESTIONS -->
        @if (searchResultLandingPages?.length || searchResultCategories?.length) {
          <div class="tw-flex tw-flex-col tw-justify-center tw-items-start">
            <div class="tw-h-8 tw-px-4 tw-py-2 tw-text-matter-neutral-secondary tw-text-xs tw-font-normal tw-leading-4 tw-w-full tw-uppercase tw-border-b-surface-neutral-4 tw-border-b tw-border-solid">
              {{ { key: 'MY_AUKRO_FILTERS_CATEGORY' } | translateSource }}
            </div>
            @for (landingPage of searchResultLandingPages; track trackBySearchResultLandingPageId(i, landingPage); let i = $index; let last = $last) {
              <auk-landing-page-item
                [landingPage]="landingPage"
                class="tw-w-full tw-border-b-surface-neutral-4 tw-cursor-pointer"
                [ngClass]="{'tw-border-b tw-border-solid tw-border-t-0 tw-border-x-0': !last || searchResultCategories?.length}"
                data-gtm="go-to-suggested-landing-page"
                (click)="onLandingPageClick($event, landingPage, i + 1)"
              ></auk-landing-page-item>
            }
            @for (category of searchResultCategories; track trackBySearchResultCategoryId(i, category); let i = $index; let last = $last) {
              <auk-category-item
                [category]="category"
                class="tw-w-full tw-border-b-surface-neutral-4 tw-cursor-pointer"
                [ngClass]="{'tw-border-b tw-border-solid tw-border-t-0 tw-border-x-0': !last}"
                data-gtm="go-to-suggested-category"
                (click)="onCategoryClick($event, category, i + 1)"
              ></auk-category-item>
            }
          </div>
        }
        <!-- ./LANDING PAGE AND CATEGORY SUGGESTIONS -->
        <!-- PRODUCT SUGGESTIONS -->
        @if (searchResultItems?.length) {
          <div class="tw-flex tw-flex-col tw-justify-center tw-items-start">
            <div class="tw-h-8 tw-px-4 tw-py-2 tw-text-matter-neutral-secondary tw-text-xs tw-font-normal tw-leading-4 tw-w-full tw-uppercase tw-border-b-surface-neutral-4 tw-border-b tw-border-solid">
              {{ { key: 'USER_TABS_COMPONENT_HTML_4' } | translateSource }}
            </div>
            @for (item of searchResultItems; track trackBySearchResultItemId(i, item); let i = $index; let last = $last) {
              <auk-suggestion-item
                [item]="item"
                class="tw-w-full tw-border-b-surface-neutral-4"
                [ngClass]="{'tw-border-b tw-border-solid tw-border-t-0 tw-border-x-0': !last}"
                (click)="onSuggestionClick(item, i)"
              ></auk-suggestion-item>
            }
          </div>
        }
        <!-- ./ PRODUCT SUGGESTIONS -->
        <!-- ALL PRODUCTS BTN-->
        @if (searchResultItemsTotal > 6) {
          <div
            class="tw-flex tw-h-12 tw-justify-center tw-items-center tw-gap-1 tw-border-t-surface-neutral-3 tw-px-4 tw-py-2 tw-border-t tw-border-solid tw-bg-surface-neutral-1 tw-cursor-pointer"
            (click)="submit($event, true)"
            >
            <span class="tw-text-matter-neutral-primary tw-text-md">{{ { key: 'HEADER_SEARCH_COMPONENT_HTML_2' } | translateSource }}</span>
            <span class="tw-flex tw-h-5 tw-min-w-[25px] tw-justify-center tw-items-center tw-gap-2 tw-px-1.5 tw-py-0 tw-rounded-[3px] tw-bg-surface-neutral-2">{{ searchResultItemsTotal }}+</span>
          </div>
        }
        <!-- ./ ALL PRODUCTS BTN -->
        <!-- USER SUGGESTIONS -->
        @if (searchResultUsers?.length) {
          @for (item of searchResultUsers; track item) {
            <auk-suggestion-user [item]="item" (click)="clearSearch()"></auk-suggestion-user>
          }
        }
        <!-- ./USER SUGGESTIONS -->
      }

    </div>
    <!-- ./DROPDOWN -->
  </div>
</div>

import isNil from 'lodash-es/isNil';
import { TranslationSource } from '@common/translations/model/translation-source';
import { WithItemStateModel } from '@shared/item-detail/service/mapper/base-item/model/with-item-state.model';
import { WithItemTypeModel } from '@shared/item-detail/service/mapper/base-item/model/with-item-type.model';
import { OfferDetailDto, OfferDetailDtoItemTypeEnum, OfferDetailDtoStateEnum } from '@api/aukro-api/model/offer-detail-dto';
import {
  ItemDetailUsersDataDto,
  ItemDetailUsersDataDtoPrivateItemUserStatusEnum,
  ItemDetailUsersDataDtoStateOfBiddingEnum,
} from '@api/aukro-api/model/item-detail-users-data-dto';
import { MoneyDto } from '@api/aukro-api/model/money-dto';
import { isNotNil } from '@util/helper-functions/is-not-nil';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { UserInterestStatisticsTransactionFeeDto } from '@api/generated/defs/UserInterestStatisticsDto';
import { Nil } from '@util/helper-types/nil';

// TODO(PDEV-15173) check use of methods and reduce this class
export class ProductStateUtils {

  public static isAuctionBuyNowAvailable(item: OfferDetailDto): boolean {
    return this.isBiddingOffer(item) && item.buyNowActive && isNil(item.highestBidderId) && item.buyNowPrice?.amount > 0;
  }

  public static isAllowedToBid(item: WithItemTypeModel & WithItemStateModel): boolean {
    return this.isBiddingOffer(item) && (item?.state === 'ACTIVE' || item?.state === 'SCHEDULED_ACTIVE');
  }

  public static isScheduled(item: WithItemStateModel): boolean {
    return item?.state === 'SCHEDULED_PUBLIC' || item?.state === 'SCHEDULED_ACTIVE';
  }

  public static isEnded(item: WithItemStateModel): boolean {
    return item?.state === 'ENDED';
  }

  public static hasStartingOrEndingTime(item: OfferDetailDto): boolean {
    return !isNil(item?.startingTime) || !isNil(item?.endingTime);
  }

  public static isActive(item: WithItemStateModel): boolean {
    return item?.state === 'ACTIVE';
  }

  public static isEndedAndNotSoldOut(item: OfferDetailDto, userData: ItemDetailUsersDataDto): boolean {
    return this.isEnded(item) && (this.isBuyNowOffer(item) &&
      item?.quantity > 0 || this.isBiddingOffer(item) && !this.hasUserBidOnItem(userData));
  }

  public static isSoldOutState(item: OfferDetailDto): boolean {
    return this.isBuyNowOffer(item) && item?.quantity === 0;
  }

  public static isBiddingOffer(item: WithItemTypeModel): boolean {
    return item?.itemType === 'BIDDING';
  }

  public static hasEndingTime(item: OfferDetailDto): boolean {
    return !isNil(item?.endingTime);
  }

  public static isBuyNowOffer(offer: WithItemTypeModel): boolean {
    return offer?.itemType === 'BUYNOW';
  }

  public static isEndedAuctionAndWinning(item: OfferDetailDto, userData: ItemDetailUsersDataDto, userId: number): boolean {
    return this.isEndedAuction(item) && this.hasUserBidOnItem(userData) && this.isUserWinning(item, userId);
  }

  public static isNotEndedAuctionAndWinning(
    item: { state?: OfferDetailDtoStateEnum; itemType?: OfferDetailDtoItemTypeEnum; highestBidderId?: number; currentBidBidderId?: number },
    userData: { stateOfBidding?: ItemDetailUsersDataDtoStateOfBiddingEnum },
    currentUserId: number,
  ): boolean {
    return !isNil(currentUserId)
      && this.isNotEndedAuction(item)
      && (this.hasUserBidOnItem(userData) || currentUserId === item.currentBidBidderId)
      && this.isUserWinning(item, currentUserId);
  }

  public static isUserWinning(offerDetail: { highestBidderId?: number }, userId: number): boolean {
    if (isNil(offerDetail) || isNil(userId)) {
      return false;
    }
    return offerDetail.highestBidderId === userId;
  }

  public static isUserLoosing(offerDetail: OfferDetailDto, userId: number): boolean {
    return offerDetail.highestBidderId !== userId;
  }

  public static isNotEndedAuctionAndLosing(
    item: { state?: OfferDetailDtoStateEnum; itemType?: OfferDetailDtoItemTypeEnum; highestBidderId?: number; currentBidBidderId?: number },
    userData: { stateOfBidding?: ItemDetailUsersDataDtoStateOfBiddingEnum },
    currentUserId: number,
  ): boolean {
    return !isNil(currentUserId)
      && this.isNotEndedAuction(item)
      && (this.hasUserBidOnItem(userData) || currentUserId === item.currentBidBidderId)
      && this.isUserLoosing(item, currentUserId);
  }

  public static isEndedAuctionAndLosing(item: OfferDetailDto, userData: ItemDetailUsersDataDto | Nil, userId: number): boolean {
    return this.isEndedAuction(item)
      && this.hasUserBidOnItem({ stateOfBidding: userData?.stateOfBidding })
      && this.isUserLoosing(item, userId);
  }

  public static hasUserBidOnItem(userData: { stateOfBidding?: ItemDetailUsersDataDtoStateOfBiddingEnum }): boolean {
    return !isNil(userData?.stateOfBidding);
  }

  public static hasAtLeastOneSoldPiece(offerDetailDto: OfferDetailDto): boolean {
    return offerDetailDto?.soldQuantity > 0;
  }

  public static isItemInCollection(item: OfferDetailDto): boolean {
    return !isNil(item?.collectionId);
  }

  public static isItemScheduled(item: WithItemStateModel): boolean {
    return item?.state === 'SCHEDULE' || item?.state === 'SCHEDULED_ACTIVE' || item?.state === 'SCHEDULED_PUBLIC';
  }

  public static isBargainingAvailable(item: OfferDetailDto, isMyOffer: boolean): boolean {
    if (isMyOffer) {
      // if item is scheduled, its offer of current user, and BO is enabled return true
      // -> so even if item is still not active, seller must see the action
      if (this.isItemScheduled(item) && item.bestOfferEnabled) {
        return true;
      }
    }
    // otherwise depend on this field
    // this changes for example, if user bids on item, then bargaining becomes disabled
    return item?.bargainingAvailable;
  }

  public static canShowSellerCommission(item: OfferDetailDto): boolean {
    return item.sellerCommission && !isNil(item.sellerCommission.sellerCommissionRate);
  }

  public static canShowSellerCommissionLimit(item: OfferDetailDto): boolean {
    return item.sellerCommission && !isNil(item.sellerCommission.sellerCommissionLimitMax);
  }

  public static getPrivateItemUserStatusTranslationSource(
    privateItemUserStatus: ItemDetailUsersDataDtoPrivateItemUserStatusEnum | null | undefined,
    depositAmount?: MoneyDto,
  ): TranslationSource | undefined {
    if (isNil(privateItemUserStatus)) {
      return {
        key: 'ITEM_DETAIL_PRIVATE_AUCTION_REQUEST_ACCESS',
      };
    }

    if (!isNil(depositAmount) && depositAmount.amount > 0 && privateItemUserStatus === 'PENDING') {
      return {
        key: 'ITEM_DETAIL_AUCTION_REQUEST_ACCESS_DIALOG_REQUEST_ACCESS_BTN_IF_PENDING',
      };
    }

    return undefined;
  }

  public static hasTransactionFee(paymentViaAukro: boolean, transactionFee: UserInterestStatisticsTransactionFeeDto | Nil): boolean {
    return paymentViaAukro && isNotNil(transactionFee);
  }

  public static isUserWinningBasedOnState(stateOfBidding: ItemDetailUsersDataDtoStateOfBiddingEnum): boolean {
    return stateOfBidding === 'WINNING';
  }

  public static isUserLosingBasedOnState(stateOfBidding: ItemDetailUsersDataDtoStateOfBiddingEnum): boolean {
    return stateOfBidding === 'LOOSING';
  }

  public static isAuction(itemTypeEnum: OfferDetailDtoItemTypeEnum): boolean {
    return itemTypeEnum === 'BIDDING';
  }

  public static isNotEndedAuction(item: WithItemTypeModel & WithItemStateModel): boolean {
    return !this.isEnded(item) && this.isBiddingOffer(item);
  }

  private static isEndedAuction(item: WithItemTypeModel & WithItemStateModel): boolean {
    return this.isEnded(item) && this.isBiddingOffer(item);
  }

}

import { Inject, Injectable, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomainUtils } from '@shared/platform/domain.utils';
import { LocaleConstants } from '@shared/platform/locale.constants';
import { DomainCode } from '@shared/platform/domain.model';
import { ExpressRequest } from '@common/platform/model/request';
import { Lang } from '@shared/platform/lang.const';
import { ICULocale } from '@shared/platform/icu-locale.model';
import { Preferences } from '@capacitor/preferences';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { Device } from '@capacitor/device';
import { REQUEST } from '../../server/const/request';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { Subject, switchMap, take } from 'rxjs';
import { AukButtonDialogComponent } from '@common/ui-kit/component/form/auk-button-dialog/component/auk-button-dialog.component';
import { AukButtonDialogModel } from '@common/ui-kit/component/form/auk-button-dialog/model/auk-button-dialog.model';
import { DomainDialogStatus } from './model/domain-dialog-status.model';
import { TransparencyService } from '@shared/services/transparency/transparency.service';
import { MatDialogService } from '@common/dialog/service/mat-dialog.service';
import { BaseDialogType } from '@common/dialog/model/base-dialog.type';
import { CookieConsentNativeService } from '@shared/services/cookie-consent-native/cookie-consent-native.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { Router } from '@angular/router';
import { DomainList } from '@common/domain/const/domain-list.const';
import isNil from 'lodash-es/isNil';

/**
 * Key that is used in preference storage for saving domain preference
 */
const MOBILE_APP_PREFERENCE_DOMAIN_KEY = 'appDomainPreference';

const CAPACITOR_LANGUAGE_MAP: Record<DomainCode, ICULocale> = {
  CZ: LocaleConstants.CZ_LOCALE,
  SK: LocaleConstants.SK_LOCALE,
};

const CAPACITOR_LANGUAGE_CODE_MAP: Record<string, DomainCode> = {
  cs: 'CZ',
  sk: 'SK',
};

/**
 * Resolves on which domain app is running and saves it.
 * Does not work on SSR.
 */
@Injectable({
  providedIn: 'root',
})
export class DomainService {

  /**
   * Tells whether app is running in CZ domain.
   * Initialized only on browser.
   */
  private _currentLocale: ICULocale = LocaleConstants.CZ_LOCALE;
  public domainInitialized$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() @Inject(REQUEST) private readonly expressRequest: ExpressRequest,
    private readonly platformCommonService: PlatformCommonService,
    private readonly cookieConsentNativeService: CookieConsentNativeService,
    private readonly transparencyService: TransparencyService,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly matDialogService: MatDialogService<BaseDialogType>,
  ) {
    if (!this.platformCommonService.isServer) {
      this.initializeDomainNameCheck();
    }
  }

  public get locale(): ICULocale {
    if (!this.platformCommonService.isServer) {
      return this._currentLocale;
    }

    return DomainUtils.resolveLocale(
      this.expressRequest.headers[LocaleConstants.NGINX_SSR_LANGUAGE_HEADER.toLowerCase()] as string,
    );
  }

  private openDomainSelector(): void {
    this.matDialogService.openSimple$<
      AukButtonDialogComponent,
      AukButtonDialogModel,
      DomainDialogStatus
    >(
      AukButtonDialogComponent,
      {
        width: '100%',
        height: '100%',
        position: { top: '0px', left: '0px' },
        data: {
          name: { key: 'DOMAIN_SELECTOR_NAME' },
          description: { key: 'DOMAIN_SELECTOR_DESCRIPTION' },
          showCloseButton: false,
          buttons: [{
            text: { defaultValue: 'Aukro.cz / Česká republika' },
            testIdentification: 'domain-selector-cz',
            accessibilityId: 'ACS_LOCATION_SELECTOR_CZ',
            action: 'CZ',
            icon: 'flag-icon-czech-republic',
            colorCombination: 'SURFACE_3',
          }, {
            text: { defaultValue: 'Aukro.sk / Slovensko' },
            action: 'SK',
            testIdentification: 'domain-selector-sk',
            accessibilityId: 'ACS_LOCATION_SELECTOR_SK',
            icon: 'flag-icon-slovakia',
            colorCombination: 'SURFACE_3',
          }],
        },
      },
    )
      .pipe(
        switchMap((dialogRef) => dialogRef.afterClosed()),
        take(1),
      )
      .subscribe({
        next: (result: DomainDialogStatus) => {
          void this.saveDomainPreference(result, true);
        },
      });
  }

  public async readDomainPreference(): Promise<void> {
    if (!PlatformCommonService.isNativeApp) {
      this.domainInitialized$.next();
      return Promise.resolve();
    }

    const preferedDomain = (await Preferences.get({ key: MOBILE_APP_PREFERENCE_DOMAIN_KEY })).value as DomainCode;
    if (isNil(preferedDomain)) {
      const domainCodeByLanguage = await this.getDomainCodeByLanguage();
      this._currentLocale = CAPACITOR_LANGUAGE_MAP[domainCodeByLanguage];
      this.openDomainSelector();
    } else {
      this._currentLocale = CAPACITOR_LANGUAGE_MAP[preferedDomain || LocaleConstants.CZ_LOCALE.country];
      void this.initCookieConsentNative();
    }

    this.domainInitialized$.next();

    return Promise.resolve();
  }

  private async getDomainCodeByLanguage(): Promise<DomainCode> {
    const languageCodeDevice = await Device.getLanguageCode();
    const defaultDomainCode = CAPACITOR_LANGUAGE_CODE_MAP[languageCodeDevice.value || this._currentLocale.country];
    if (defaultDomainCode) {
      return defaultDomainCode;
    }
    return LocaleConstants.CZ_LOCALE.country;
  }

  public async saveDomainPreference(domain: DomainCode, forceReload: boolean = false): Promise<void> {
    await Preferences.set({
      key: MOBILE_APP_PREFERENCE_DOMAIN_KEY,
      value: domain,
    });

    if (CAPACITOR_LANGUAGE_MAP[domain] !== this._currentLocale) {
      this.authenticationService.logout(true);

      void this.router.navigate(['/']);

      this.window.location.reload();
    } else {
      if (forceReload) {
        this.window.location.reload();
      }

      void this.initCookieConsentNative();
    }
  }

  public get hostname(): string {
    return this.domain === 'CZ' ? DomainList.CZ : DomainList.SK;
  }

  private async initCookieConsentNative(): Promise<void> {
    if (PlatformCommonService.isNativeIosApp) {
      const allowed = await this.transparencyService.transparencyIosRequest();
      if (allowed) {
        this.cookieConsentNativeService.initCookieConsentNative(this.domain);
      } else {
        // We need to initialize Didomi even if user has refused ATT
        this.cookieConsentNativeService.initCookieConsentNative(this.domain, true);
      }
      return Promise.resolve();
    } else {
      this.cookieConsentNativeService.initCookieConsentNative(this.domain);
      return Promise.resolve();
    }
  }

  /**
   * Returns domain string
   */
  public get domain(): DomainCode {
    return this.locale.country;
  }

  /**
   * Returns locale string
   */
  public get lang(): Lang {
    return this.locale.language;
  }

  public get hrefLangLocale(): string {
    return this.locale.hrefLangLocale;
  }

  /**
   * Returns true if locale is 'CZ locale'
   */
  public isCzLocale(): boolean {
    return this.locale === LocaleConstants.CZ_LOCALE;
  }

  /**
   * Returns true if locale is 'SK locale'
   */
  public isSkLocale(): boolean {
    return this.locale === LocaleConstants.SK_LOCALE;
  }

  /**
   * Browser locale check.
   */
  private initializeDomainNameCheck(): void {
    if (PlatformCommonService.isNativeApp) {
      return;
    }
    this._currentLocale = DomainUtils.resolveLocale(this.document.location?.hostname);
  }

}

import { Injectable, OnDestroy } from '@angular/core';
import { App, BackButtonListenerEvent, URLOpenListenerEvent } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationStart, Router } from '@angular/router';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { PluginListenerHandle } from '@capacitor/core';
import { Location } from '@angular/common';
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { CookieService } from '@common/cookie/service/cookie.service';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import moment from 'moment-mini-ts';
import { UrlService } from '@shared/platform/url.service';
import { take, takeUntil, lastValueFrom, from, mergeMap, combineLatestWith, Observable, of, filter, switchMap, finalize, BehaviorSubject } from 'rxjs';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Dialog } from '@capacitor/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@util/util/date.utils';
import { HttpClient } from '@angular/common/http';
import { captureException, captureMessage } from '@sentry/browser';
import { Network } from '@capacitor/network';
import { DomainUtils } from '@shared/platform/domain.utils';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { Directory, Filesystem, FileInfo } from '@capacitor/filesystem';
import { Nil } from '@util/helper-types/nil';

const updaterLinks = {
  production: 'https://app-update.aukro.cz/update',
  test: 'https://app-update.qa.aukro.cloud/update',
};

@Injectable({
  providedIn: 'root',
})
export class NativeAppService extends NgUnsubscribe implements OnDestroy {

  private backButtonListener: PluginListenerHandle;
  private appUrlOpenListener: PluginListenerHandle;

  public isUpdateDownloadInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUpdateCheckInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private capacitorBundleUpdate: BundleInfo | Nil = null;

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly cookieService: CookieService,
    private readonly urlService: UrlService,
    private readonly translateService: TranslateService,
    private readonly httpClient: HttpClient,
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
    super();
  }

  public init(): void {
    if (!PlatformCommonService.isNativeApp) {
      return;
    }

    void this.cleanOldAppBundles();
    void CapacitorUpdater.notifyAppReady();
    void this.registerBackSwipeListener();
    void this.registerDeepLinkListener();
    void this.replaceHistoryForNativeBackButton();
    void StatusBar.setStyle({ style: Style.Light });
    void StatusBar.setBackgroundColor({ color: '#FFFFFF' });

    if (PlatformCommonService.isNativeIosApp && 'scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    //Continuous update chceck every 5 minutes
    this.ngZoneUtilService.intervalOut$(DateUtils.convertMinutesToMilliseconds(5))
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.updaterModalCheck();
      });

    //First update check after app start
    this.ngZoneUtilService.simpleTimerOut$(
      () => {
        this.updaterModalCheck();
      },
      this.ngUnsubscribe,
      DateUtils.convertSecondsToMilliseconds(30),
    );
  }

  private replaceHistoryForNativeBackButton(): void {
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: NavigationStart) => {
        if (event instanceof NavigationStart && event.url === '/404') {
          this.location.replaceState('/');
        }
      });
  }

  private async registerBackSwipeListener(): Promise<void> {
    this.backButtonListener = await App.addListener('backButton', (data: BackButtonListenerEvent) => {
      if (data.canGoBack) {
        this.location.back();
      } else {
        void App.exitApp();
      }
    });
  }

  private checkForMinimalVersion(): Observable<boolean> {
    return this.configuratorCacheService.getFeSystemParam<string>(
      'MINIMUM_MOBILE_FE_APP_REQUIRED',
      'STRING',
    )
      .pipe(
        combineLatestWith(from(CapacitorUpdater.current())),
        mergeMap(([updateDataVersion, curentBundle]: [string, { bundle: BundleInfo; native: string }]) => {
          if (updateDataVersion) {
            return of(this.isUpdateNeeded(curentBundle.bundle.version, updateDataVersion));
          }
          return of(false);
        }),
      );
  }

  private isUpdateNeeded(currentVersion, updateVersion): boolean {
    const updateNeeded = updateVersion.localeCompare(
      currentVersion || '0', undefined, { numeric: true, sensitivity: 'base' });
    if (updateNeeded === 1 || currentVersion === 'builtin') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This function accepts file and directory and deletes the file / directory if its missplaced
   * Versions is the only folder where we save any data (actual place for app packages)
   * @param file FileInfo from Filesystem.readdir
   * @param directory Directory from Capacitor
   */
  private async cleanData(file: FileInfo, directory: Directory): Promise<void> {
    if (file.uri.includes('versions')) {
      return;
    }
    if (file.type === 'directory') {
      try {
        await Filesystem.rmdir({ path: file.name, directory, recursive: true });
      } catch {
        captureException(new Error('Bundle Native Custom directory Delete Error'), { tags: { context: 'native', result: 'failure' } });
      }
    }
    if (file.type === 'file') {
      try {
        await Filesystem.deleteFile({ path: file.name, directory });
      } catch {
        captureException(new Error('Bundle Native Custom file Error'), { tags: { context: 'native', result: 'failure' } });
      }
    }
  }

  private async cleanOldAppBundles(): Promise<void> {
    const currentBundleId = (await CapacitorUpdater.current()).bundle?.id;
    const listOfPackages = await CapacitorUpdater.list();

    const documents = await Filesystem.readdir({ path: '/', directory: Directory.Documents });
    const data = await Filesystem.readdir({ path: '/', directory: Directory.Data });

    documents.files.forEach(file => {
      void this.cleanData(file, Directory.Documents);
    });

    data.files.forEach(file => {
      void this.cleanData(file, Directory.Data);
    });

    if (listOfPackages?.bundles?.length < 2 && !currentBundleId) {
      return;
    }

    listOfPackages.bundles.forEach(bundle => {
      if (bundle.id !== currentBundleId) {
        try {
          void CapacitorUpdater.delete({ id: bundle.id });
        } catch {
          captureException(new Error('Bundle Native Delete Error'), { tags: { context: 'native', result: 'failure' } });
        }
      }
    });
  }

  public async checkUpdate(): Promise<void> {
    if (this.isUpdateCheckInProgress.value) {
      return;
    }

    if (this.capacitorBundleUpdate) {
      void this.confirmAppUpdate(this.capacitorBundleUpdate, false);
    }

    this.isUpdateCheckInProgress.next(true);

    const translations = await lastValueFrom(
      this.translateService.get(
        ['APP_UPDATE_HEADER', 'APP_UPDATE_DOWNLOAD', 'APP_UPDATE_CONFIRM', 'APP_UPDATE_UNAVAILABLE', 'APP_UPDATE_CHECK']),
    );

    this.isUpdateAvailable()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      ).subscribe((updateData: { url: string; version: string } | boolean) => {
        if (typeof updateData === 'object' && updateData.url && updateData.version) {
          void Dialog.alert({
            title: translations['APP_UPDATE_HEADER'],
            message: translations['APP_UPDATE_DOWNLOAD'],
            buttonTitle: translations['APP_UPDATE_CONFIRM'],
          });

          void this.downloadAppUpdate(updateData);
        } else {
          void Dialog.alert({
            title: translations['APP_UPDATE_CHECK'],
            message: translations['APP_UPDATE_UNAVAILABLE'],
            buttonTitle: translations['APP_UPDATE_CONFIRM'],
          });

          this.isUpdateCheckInProgress.next(false);
        }
      });
  }

  public updaterModalCheck(): void {
    if (this.isUpdateCheckInProgress.value) {
      return;
    }

    if (this.capacitorBundleUpdate) {
      void this.confirmAppUpdate(this.capacitorBundleUpdate, false);
    }

    this.isUpdateCheckInProgress.next(true);

    this.isUpdateAvailable()
      .pipe(
        filter((updateData) => {
          if (updateData === false) {
            this.isUpdateCheckInProgress.next(false);
          }
          return updateData !== false;
        }),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((updateData: { url: string; version: string }) => {
        void this.downloadAppUpdate(updateData);
      });
  }

  private async downloadAppUpdate(updateData: { url: string; version: string }): Promise<void> {
    if (this.isUpdateDownloadInProgress.value) {
      return;
    }

    this.isUpdateDownloadInProgress.next(true);

    //This will download package on background and then apply it after user confirms the modal
    const data = await CapacitorUpdater?.download({
      version: updateData.version,
      url: updateData.url,
    });

    this.checkForMinimalVersion()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((forceUpdate) => {
        void this.confirmAppUpdate(data, forceUpdate);
      });

    const status = await Network.getStatus();

    if (status.connectionType === 'cellular') {
      captureMessage(`Downloading app update via MobileData - ${ JSON.stringify(updateData) }`);
    }
  }

  private confirmAppUpdate(data: BundleInfo, forceUpdate: boolean): Promise<void> {
    if (forceUpdate) {
      this.translateService.get(
        ['APP_UPDATE_FORCED_HEADER', 'APP_UPDATE_FORCED_TEXT', 'APP_UPDATE_YES'])
        .pipe(
          switchMap(translations =>
            from(Dialog.alert({
              title: translations['APP_UPDATE_FORCED_HEADER'],
              message: translations['APP_UPDATE_FORCED_TEXT'],
              buttonTitle: translations['APP_UPDATE_YES'],
            })),
          ),
          take(1),
          takeUntil(this.ngUnsubscribe),
          finalize(() => {
            this.isUpdateCheckInProgress.next(false);
            this.isUpdateDownloadInProgress.next(false);
          }),
        ).subscribe(() => {
          try {
            void CapacitorUpdater?.set(data);
            captureMessage(`Applying forced update for ${ JSON.stringify(data) }`);
          } catch (err) {
            console.log(err);
            captureException(err);
          }
        });

      return;
    }

    this.translateService.get(
      ['APP_UPDATE_HEADER', 'APP_UPDATE_TEXT', 'APP_UPDATE_YES', 'APP_UPDATE_NO'])
      .pipe(
        switchMap(translations =>
          from(Dialog.confirm({
            title: translations['APP_UPDATE_HEADER'],
            message: translations['APP_UPDATE_TEXT'],
            okButtonTitle: translations['APP_UPDATE_YES'],
            cancelButtonTitle: translations['APP_UPDATE_NO'],
          })),
        ),
        take(1),
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isUpdateCheckInProgress.next(false);
          this.isUpdateDownloadInProgress.next(false);
        }),
      ).subscribe((result) => {
        if (result.value) {
          try {
            void CapacitorUpdater?.set(data);
          } catch (err) {
            console.log(err);
            captureException(err);
          }
        } else {
          this.capacitorBundleUpdate = data;
          void CapacitorUpdater?.next(data);
        }
      });
  }

  public isUpdateAvailable(): Observable<boolean | { url: string; version: string }> {
    return this.httpClient.get(PlatformCommonService.isProductionMode ? updaterLinks.production : updaterLinks.test)
      .pipe(
        combineLatestWith(from(CapacitorUpdater.current())),
        mergeMap(([updateData, curentBundle]: [{ version: string; url: string }, { bundle: BundleInfo; native: string }]) => {
          if (this.isUpdateNeeded(curentBundle.bundle.version, updateData.version)) {
            return of({
              version: updateData.version,
              url: updateData.url,
            });
          } else {
            return of(false);
          }
        }),
      );
  }

  private async registerDeepLinkListener(): Promise<void> {
    this.appUrlOpenListener = await App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.ngZoneUtilService.runIn(() => {
        // On Android sometimes from Barion App opens firectly with our custom opener, we need to remove /open-android part
        const cleanUrl = event.url?.replace('/open-android', '');
        const url = new URL(cleanUrl);
        if (url?.pathname) {
          this.urlService.reverseTranslateUrl(url.pathname + url.search, DomainUtils.getHostnameDomain(url.hostname))
            .pipe(
              take(1),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (translatedRoute: string) => {
                void this.router.navigateByUrl(translatedRoute);
              },
            });
        }
      });
    });
  }

  /**
   * Saves the Aukro token to cookie manually if it is a native app.
   * (In native iOS apps, cookies from the Set-Cookie header are not readable in JS.
   * Therefore, we need to manually save the Aukro token to a cookie.)
   * @param aukroToken to be saved
   */
  public saveAukroTokenIfNativeApp(aukroToken: string): void {
    if (!PlatformCommonService.isNativeApp) {
      return;
    }

    this.cookieService.put(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY, aukroToken, {
      expires: moment().add(365, 'days').toDate(),
    });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    void this.appUrlOpenListener?.remove();
    void this.backButtonListener?.remove();
  }

}

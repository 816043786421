import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileModel } from '@common/ui-kit/component/tile/model/tile.model';
import { Translate2Module } from '@common/translations/translate2.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { RouterModule } from '@angular/router';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { TileConstant } from '@common/ui-kit/component/tile/constant/tile.constant';
import { DynamicColorDirective } from '@common/dynamic-color/dynamic-color.directive';
import { NilToEmptyStringPipe } from '@common/string/pipe/nil-to-empty-string.pipe';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { BaseTileComponent } from '@common/ui-kit/component/tile/component/base-tile.component';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { TileColorType } from '../../model/tile-color-type.model';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';

@Component({
  selector: 'auk-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    Translate2Module,
    IconComponent,
    DynamicColorDirective,
    NilToEmptyStringPipe,
    MatCheckboxModule,
    InnerHtmlDirective,
    LinkFromHrefDirective,
    TestIdentificationDirective,
  ],
})
export class TileComponent<T> extends BaseTileComponent<T> implements OnChanges, OnInit {

  @Input() public override tile: TileModel<T>;
  @Input() public type: 'SIMPLE' | 'NORMAL' | 'CHECKBOX' = 'NORMAL';
  @Input() public size: 'MOBILE_SMALL' | 'SMALL' | 'BIG' = 'SMALL';
  @Input() public isSelected: boolean = false;

  public containerDesignClass = [];

  public ngOnInit(): void {
    this.generateDesignClass();
  }

  public ngOnChanges(changes: AukSimpleChanges<TileComponent<T>>): void {
    if (changes.type || changes.isSelected) {
      this.generateDesignClass();
    }
  }

  private generateDesignClass(): void {
    this.containerDesignClass = [];
    if (this.type === 'SIMPLE') {
      this.containerDesignClass.push(...['tw-pl-6', 'tw-pr-4'], ...this.getColorClasses());
    } else {
      this.containerDesignClass.push(...['lg:hover:tw-shadow', 'tw-shadow', 'tw-border-b-2', 'tw-border-solid'], ...this.getColorClasses());
    }
  }

  private getColorClasses(): string[] {
    if (this.getColorType() === 'INVERT') {
      return ['tw-bg-surface-accent-3', 'tw-border-b-surface-accent-3', 'lg:hover:tw-border-b-surface-accent-5'
        , '!tw-text-matter-neutral-primary-invert', 'lg:hover:tw-bg-surface-accent-button-hover',
        'active:!tw-bg-surface-accent-5', 'active:hover:tw-shadow'];
    }

    if (this.getColorType() === 'SECONDARY') {
      return ['tw-bg-surface-neutral-1', 'tw-border-b-surface-neutral-1', 'lg:hover:tw-border-b-surface-neutral-2'
        , '!tw-text-matter-neutral-primary-invert', 'lg:hover:tw-bg-surface-neutral-2',
        'active:!tw-bg-surface-neutral-1', 'active:hover:tw-shadow'];
    }

    //DEFAULT
    return ['tw-bg-contrast', 'tw-border-b-contrast', 'lg:hover:tw-border-b-surface-accent-4', '!tw-text-matter-neutral-primary',
      'active:!tw-bg-silverstein-100', 'active:hover:tw-shadow'];
  }

  private getColorType(): TileColorType {
    if (isNotNil(this.tile?.colorType)) {
      return this.tile.colorType;
    }
    //Fallback for old CEs
    if (this.tile?.backgroundColor === 'var(--auk-primus-500)' || this.tile?.backgroundColor === 'var(--auk-clr-antique)') {
      return 'INVERT';
    }

    return 'DEFAULT';
  }

  protected get textSize(): string {
    switch (this.size) {
      case 'BIG':
        return 'tw-text-md';
      default:
        return 'tw-text-sm tw-leading-4';
    }
  }

  protected get textColor(): string {
    return this.tile.textColor ?? TileConstant.DEFAULT_TEXT_COLOR;
  }

}

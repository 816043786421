import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment-mini-ts';
import { debounceTime } from 'rxjs/operators';
import { SentryService } from '@shared/services/logging/sentry.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { AukError } from '@common/error/domain/auk-error';
import { ErrorUtil } from '@common/error/util/error-util';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

interface OfflineErrorStack {
  url?: string;
  aukroToken?: string;
}

@Injectable()
export class AukroErrorHandlerService implements ErrorHandler {

  private offlineErrorsStack: OfflineErrorStack[] = [];

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly platformService: PlatformService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly sentryService: SentryService,
  ) {
    if (this.sentryService.sentryEnabled) {
      this.initOfflineLog();
    }
  }

  public handleError(error: any | AukError): void {
    if (ErrorUtil.canLogError(error, PlatformCommonService.isDevMode)) {
      return;
    }

    console.error(error);

    if (PlatformCommonService.isDevMode) {
      return;
    }

    // if error is chunk load error, try manually force refresh page
    if (
      error?.message
      && typeof error.message === 'string'
      && error.message.includes('ChunkLoadError')
    ) {
      const reloadedAt = this.window.sessionStorage.getItem('reloadedAt');

      if (!reloadedAt || moment(reloadedAt).add('3', 'minutes').toDate() < new Date()) {
        this.window.sessionStorage.setItem('reloadedAt', moment().toDate().toString());
        this.window.location.reload();
        return;
      }
    }
  }

  private initOfflineLog(): void {
    // check online status
    this.platformService.isUserOnline()
      .pipe(
        debounceTime(5000),
      )
      .subscribe((isOnline: boolean) => {
        // if user comes into offline store data
        if (!isOnline) {
          this.offlineErrorsStack.push({
            url: this.router.url,
            aukroToken: this.localStorageService.getItem(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY),
          });
        } else if(isOnline && this.offlineErrorsStack.length > 0) {
          this.offlineErrorsStack = [];
        }
      });
  }

}

import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { COLOR_COMBINATIONS } from '@common/colors/constant/color-combinations';

export class TileConstant {

  public static readonly ARROW_ICON: IconModel = {
    source: 'right-arrow',
    type: 'SVG',
    size: 'LG',
    colorCombination: 'PRIMARY_CONTRAST',
  };

  public static readonly CATEGORY_FILE_ICON: IconModel = {
    source: 'chevron-box',
    type: 'SVG',
    size: 'LG',
    colorCombination: 'PRIMARY_CONTRAST',
  };

  public static readonly DEFAULT_BACKGROUND_COLOR: string = COLOR_COMBINATIONS.SECONDARY.backgroundColor.base;
  public static readonly DEFAULT_TEXT_COLOR: string = COLOR_COMBINATIONS.SILVERSTEIN_900.backgroundColor.base;
  public static readonly DEFAULT_COLUMNS_ON_MOBILE: number = 2;
  public static readonly DEFAULT_MIN_TILE_WIDTH: string = '11.5rem';

}

import { Injectable } from '@angular/core';
import { DynamicColorResolver } from '@common/dynamic-color/dynamic-color.resolver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubbrandService } from './subbrand.service';
import { SubbrandType } from '../model/subbrand.type';
import { DynamicColorClasses } from '@common/dynamic-color/model/dynamic-color-classes.model';
import { Nil } from '@util/helper-types/nil';

const COLOR_CLASS_MAP: Record<SubbrandType, string[]> = {
  ANTIQUE: ['hover:!tw-border-b-antique', 'active:!tw-border-b-antique'],
  BAZAAR: ['hover:!tw-border-b-primary', 'active:!tw-border-b-primary'],
  NOT_SPECIFIED: ['hover:!tw-border-b-silverstein-100', 'active:!tw-border-b-silverstein-100'],
};

@Injectable({
  providedIn: 'root',
})
export class SubbrandsDynamicColorResolver extends DynamicColorResolver {

  constructor(
    private readonly subbrandService: SubbrandService,
  ) {
    super();
  }

  public override getColorClasses$(): Observable<DynamicColorClasses> {
    return this.subbrandService.sessionSubbrand$
      .pipe(
        map((subbrand) => this.mapColorsToClasses(subbrand)),
      );
  }

  private mapColorsToClasses(subbrand?: SubbrandType | Nil): DynamicColorClasses {
    return {
      addColorClassList: COLOR_CLASS_MAP[subbrand],
      removeColorClassList: [].concat(...Object.values(COLOR_CLASS_MAP)) as string[],
    };
  }

}
